import React, { useState, useEffect } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { NavLink } from 'react-router-dom';
import _fetch from '../config/api';
import { base_url } from '../config/config';
import toast from 'react-hot-toast';
import ProfileSidebar from './ProfilePages/ProfileSidebar';
import ProfileTopHeader from './ProfilePages/ProfileTopHeader';

const ChangePassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [phone, setPhone] = useState({ countryCode: '', phone: '' });
    const [activeTab, setActiveTab] = useState('email');
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [phoneCountdown, setPhoneCountdown] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [isCpVisible, setIsCpVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        let timer;
        if (emailCountdown > 0) {
            timer = setTimeout(() => {
                setEmailCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [emailCountdown]);

    useEffect(() => {
        let timer;
        if (phoneCountdown > 0) {
            timer = setTimeout(() => {
                setPhoneCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [phoneCountdown]);

    const sendEmailOTP = async () => {
        try {
            if (!emailIsValid(email)) {
                toast.dismiss();
                toast.error('Invalid Email address');
                return;
            }

            let requestData = {
                user_type: "email",
                email,
            };

            const response = await _fetch(`${base_url}/auth/forgotpasswordotp`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response?.message);
                setEmailCountdown(60);
            } else {
                toast.dismiss();
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        }
    };

    const sendPhoneOTP = async () => {
        try {
            const phoneNumber = phone.phone.substring(phone.countryCode.length);

            if (!validatePhone(phoneNumber)) {
                toast.dismiss();
                toast.error('Invalid Phone number');
                return;
            }

            let requestData = {
                user_type: "phone",
                phone: phoneNumber,
                country_code: `+${phone.countryCode}`,
            };

            const response = await _fetch(`${base_url}/auth/forgotpasswordotp`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response?.message);
                setPhoneCountdown(60);
            } else {
                toast.dismiss();
                toast.error('Failed to send OTP to phone');
            }
        } catch (error) {
            console.error('Error sending phone OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to phone');
        }
    };

    const handleEmailForword = async () => {
        try {
            if (!email || !password || !otp || !confirmPassword) {
                toast.dismiss();
                toast.error('Please enter Email, Password, OTP, and Confirm Password');
                return;
            }

            if (password !== confirmPassword) {
                toast.dismiss();
                toast.error('Passwords do not match');
                return;
            }

            let requestData = {
                email,
                password,
                confirmPassword,
                user_type: "email",
                otp,
            };

            const response = await _fetch(`${base_url}/auth/changepass`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response?.message);
                setEmail('');
                setPassword('');
                setOtp('');
                setConfirmPassword('');
            } else {
                toast.dismiss();
                toast.error(response?.message);
            }
        } catch (error) {
            console.error('Error with email Forget:', error);
            toast.dismiss();
            toast.error('An error occurred during email Forget');
        }
    };

    const handlePhoneForword = async () => {
        try {
            if (!phone.phone || !phone.countryCode || !password || !otp || !confirmPassword) {
                toast.dismiss();
                toast.error('Please enter Phone number, Country Code, Password, OTP and Confirm Password');
                return;
            }

            if (password !== confirmPassword) {
                toast.dismiss();
                toast.error('Passwords do not match');
                return;
            }
            const phoneNumber = phone.phone.substring(phone.countryCode.length);

            let requestData = {
                phone: phoneNumber,
                password,
                confirmPassword,
                country_code: `+${phone.countryCode}`,
                user_type: "phone",
                otp,
            };

            const response = await _fetch(`${base_url}/auth/changepass`, 'POST', requestData, {});

            if (response.success === 1) {
                toast.dismiss();
                toast.success(response?.message);
                setPhone({ countryCode: '', phone: '' });
                setPassword('');
                setOtp('');
                setConfirmPassword('');
            } else {
                toast.dismiss();
                toast.error('Changed Password failed. Please check your credentials.');
            }
        } catch (error) {
            console.error('Error with phone Forget:', error);
            toast.error('An error occurred during phone Forget');
        }
    };

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhone = (phoneNumber) => {
        const pattern = phonePatterns[phone.countryCode];
        if (pattern) {
            return pattern.test(phoneNumber);
        }
        return /^\+?[1-9]\d{1,14}$/.test(phoneNumber);
    };

    const phonePatterns = {
        'US': /^\+1\d{10}$/,
    };

    return (
        <>

            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-md-13 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />

                                <div className='change-pass-main'>


                                    <div className='change-row mt-5 mb-5'>
                                        <div className='reset-page-title'>
                                            <h3>Reset Password </h3>
                                        </div>
                                        <div className=''>
                                            <div className='form-div formdiv-1'>
                                                {/* <ul className="nav nav-pills" role="tablist">
                                                        <li className="nav-item">
                                                            <a
                                                                className={`nav-link navlink-1 ${activeTab === 'email' ? 'active' : ''}`}
                                                                onClick={() => setActiveTab('email')}
                                                                data-bs-toggle="pill"
                                                                href="#home"
                                                            >
                                                                Email
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className={`nav-link navlink-1 ${activeTab === 'phone' ? 'active' : ''}`}
                                                                onClick={() => setActiveTab('phone')}
                                                                data-bs-toggle="pill"
                                                                href="#menu1"
                                                            >
                                                                Phone
                                                            </a>
                                                        </li>
                                                    </ul> */}
                                                <div className="tab-content">
                                                    <div id="home" className={`container tab-pane ${activeTab === 'email' ? 'active' : ''} login-form`}>
                                                        <form className='mt-3'>
                                                            <div className='email-flex-div1 d-flex w-100'>
                                                                <div className='w-100 form-group'>
                                                                    <label>Email</label>
                                                                    <input type="text" placeholder='Enter Your Email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control mt-2' />
                                                                </div>
                                                                <div className='otp-btn-div'>
                                                                    <button type='button' onClick={sendEmailOTP} disabled={emailCountdown > 0} className='btn otp-btn'>{emailCountdown > 0 ? ` (${emailCountdown}s)` : 'Send OTP'}</button>
                                                                </div>
                                                            </div>
                                                            <div className='form-group mt-3'>
                                                                <label>Email Verification Code</label>
                                                                <input type="text" placeholder="Enter Code" value={otp} onChange={(e) => setOtp(e.target.value)} className="form-control mt-2" />
                                                            </div>
                                                            <div className='form-group mt-3 password-div'>
                                                                <label>New Password</label>
                                                                <input type={isVisible ? "text" : "password"} placeholder="Enter New Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                                <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                                    {
                                                                        isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='form-group mt-3 password-div'>
                                                                <label>Confirm Password</label>
                                                                <input type={isCpVisible ? "text" : "password"} placeholder=" Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control mt-2" />
                                                                <span className='eye-icon-div' onClick={() => setIsCpVisible(!isCpVisible)}>
                                                                    {
                                                                        isCpVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='form-group mt-4'>
                                                                <button type='button' onClick={handleEmailForword} className='btn login-btn'>Change Password</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div id="menu1" className={`container tab-pane ${activeTab === 'phone' ? 'active' : ''} login-form`}>
                                                        <form className='mt-3'>
                                                            <div className='email-flex-div1 d-flex'>
                                                                <div className='form-group w-100'>
                                                                    <label>Phone</label>
                                                                    <PhoneInput
                                                                        country={'in'}
                                                                        value={phone.phone}
                                                                        onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
                                                                        inputProps={{
                                                                            name: 'phone',
                                                                            required: true,
                                                                            autoFocus: true
                                                                        }}
                                                                        inputClass={'form-control w-100 mt-2'}
                                                                    />
                                                                </div>
                                                                <div className='otp-btn-div'>
                                                                    <button type='button' onClick={sendPhoneOTP} disabled={phoneCountdown > 0} className='btn otp-btn'>{phoneCountdown > 0 ? ` (${phoneCountdown}s)` : 'Send OTP'}</button>
                                                                </div>
                                                            </div>
                                                            <div className='form-group mt-3'>
                                                                <label>Phone Verification Code</label>
                                                                <input type="text" placeholder="Enter Code" value={otp} onChange={(e) => setOtp(e.target.value)} className="form-control mt-2" />
                                                            </div>
                                                            <div className='form-group mt-3 password-div'>
                                                                <label>New Password</label>
                                                                <input type={isVisible ? "text" : "password"} placeholder="Enter New  Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                                <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                                    {
                                                                        isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='form-group mt-3 password-div'>
                                                                <label>Confirm Password</label>
                                                                <input type={isCpVisible ? "text" : "password"} placeholder=" Enter New Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control mt-2" />
                                                                <span className='eye-icon-div' onClick={() => setIsCpVisible(!isCpVisible)}>
                                                                    {
                                                                        isCpVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className='form-group mt-4'>
                                                                <button type='button' onClick={handlePhoneForword} className='btn login-btn'>Change Password</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    );
};

export default ChangePassword;
