// import React, { useEffect, useState } from 'react'
// import pp from '../../assets/img/user-blue.png'
// import { NavLink } from 'react-router-dom'
// import { FaUser } from 'react-icons/fa'
// import { base_url } from '../../config/config'

// const ProfileSidebar = () => {

//     const token = localStorage.getItem("token");
//     const [userData, setUserData] = useState();
//     console.log('userdata-->', userData);
//     const getUserDetail = async () => {
//         const response = await fetch(`${base_url}/v1/userProfile`, {
//             method: 'get',
//             headers: {
//                 Authorization: token,
//                 'Content-Type': 'application/json'
//             }
//         });
//         const data = await response.json();
//         if (data) {
//             setUserData(data.user)

//         } else {

//         }
//     };
//     useEffect(() => {
//         getUserDetail();

//     }, []);

//   return (
//     <div className='pf-sidebar'>
//         <div className='pf-sidebar-header'>
//             <div className='pf-image'>
//                 <img src={userData.signatureImage} alt='profile-icon'/>
//             </div>
//             <h5 className='mt-1'>{userData ? userData.firstname : 'User'} {userData ? userData.lastname : ''} </h5>
//             <p>UID: {userData ? userData.username : ''} </p>
//         </div>

//         <div className='pf-sidebar-menu'>
//             <ul>
//                 <li >
//                     <NavLink to='/kycprofiledetail' className=''><span className='sidebar-span-dflex'><FaUser className="sidebar-user-icon1"/></span> My Profile</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/userkycprofile'><span className='sidebar-span-dflex'><FaUser className="sidebar-user-icon1"/></span>KYC Verification</NavLink>
//                 </li>
//                 <li>
//                     <NavLink to='/changepassword'><span className='sidebar-span-dflex'><FaUser className="sidebar-user-icon1"/></span>Change Password</NavLink>
//                 </li>

//             </ul>
//         </div>

//     </div>
//   )
// }

// export default ProfileSidebar
import React, { useEffect, useState } from 'react';
import pp from '../../assets/img/user-blue.png';
import { NavLink } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { base_url } from '../../config/config';
import { AiFillSound } from 'react-icons/ai';
import { CiCoinInsert } from 'react-icons/ci';
import { PiHandCoinsBold } from 'react-icons/pi';
import { TbPasswordUser } from 'react-icons/tb';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import logo4 from '../../assets/img/Logo/logo4.png';

const ProfileSidebar = () => {
    const token = localStorage.getItem("token");
    // const [userData, setUserData] = useState(null);

    // const getUserDetail = async () => {
    //     try {
    //         const response = await fetch(`${base_url}/v1/userProfile`, {
    //             method: 'get',
    //             headers: {
    //                 Authorization: token,
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         if (data.success === 1) {
    //             setUserData(data.user);
    //         } else {
    //             // Handle error if needed
    //         }
    //     } catch (error) {
    //         console.error('Error fetching user data:', error);
    //         // Handle error if needed
    //     }
    // };
    // console.log('userData', userData?.signatureImage);

    useEffect(() => {
        // getUserDetail();
    }, []);

    return (
        <div className='pf-sidebar'>
            <div className='pf-sidebar-header'>
                <div className='pf-image'>
                    {/* <img src=
                {userData?.signatureImage ? userData?.signatureImage : pp}
                 alt='profile-icon' /> */}
                    <img src={logo4} alt='profile-icon' />
                    {/* <img src={userData && userData.signatureImage ? userData.signatureImage : pp} alt='profile-icon' /> */}
                </div>
                {/* <h5 className='mt-1'>{userData ? `${userData.firstname} ${userData.lastname}` : 'User'}</h5>
                <p>UID: {userData ? userData.username : ''}</p> */}
            </div>

            <div className='pf-sidebar-menu'>
                <ul>
                    <li>
                        <NavLink to='/kycprofiledetail' className=''><span className='sidebar-span-dflex'><FaUser className="sidebar-user-icon1" /></span> My Profile</NavLink>
                    </li>
                    <li>
                        <NavLink to='/userkycprofile'><span className='sidebar-span-dflex'><MdOutlineVerifiedUser className="sidebar-user-icon1" /></span>KYC Verification</NavLink>
                    </li>
                    <li>
                        <NavLink to='/changepassword'><span className='sidebar-span-dflex'><TbPasswordUser className="sidebar-user-icon1" /></span>Change Password</NavLink>
                    </li>
                    <li>
                        <NavLink to='/referral-link'><span className='sidebar-span-dflex'><AiFillSound className="sidebar-user-icon1" /></span>Referral & Earn</NavLink>
                    </li>
                    <li>
                        <NavLink to='/referral-bonus'><span className='sidebar-span-dflex'><PiHandCoinsBold className="sidebar-user-icon1" /></span>Referral Bonus</NavLink>
                    </li>
                    <li>
                        <NavLink to='/bug_reports'><span className='sidebar-span-dflex'><PiHandCoinsBold className="sidebar-user-icon1" /></span>Bug Reports</NavLink>
                    </li>

                </ul>
            </div>
        </div>
    );
}

export default ProfileSidebar;
