import React from 'react'
import security from '../../assets/img/seq11.png'
import security01 from '../../assets/img/s-2.png'
import security02 from '../../assets/img/s-1.png'
const Security = () => {
    return (
        <>
            

            <section className='security_01'>
                <div className='container'>
                <div className='row'>
                        <div className='col-md-5 text-center '>
                                <img className='about_banner_img w-50 ' src={security02} alt='' />
                        </div>
                        <div className='col-lg-7 align-self'>
                                <h4 className='security_sub_title sb outfit'>Security</h4>
                                <h1 className='w outfit security-heading'>At Bitnetto, we take security seriously.</h1>
                                <p className='banner_text w outfit'>We've worked hard to keep your crypto safe. Our security system has many layers to protect your assets. We have firewalls and systems in place to detect anything suspicious.</p>
                                <p className='banner_text w outfit'>But that's not all - we don't just rely on technology. We also regularly check and test our security to make sure it's strong. And if anything seems wrong, our expert team is ready to act.</p>
                                <p className='banner_text w outfit'>If you want a safe exchange for your crypto, choose Bitnetto. We're here to protect you, so you can focus on your crypto journey.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Security
