import React, { useEffect, useContext, useState } from 'react'
import token1 from '../assets/icons/bnb.png';
import { IoTrendingDownOutline, IoTrendingUpOutline } from 'react-icons/io5';
import { TbActivityHeartbeat, TbArrowsUpDown } from 'react-icons/tb';
import { CiCircleQuestion } from 'react-icons/ci';
import { MdOutlineAccessTime } from 'react-icons/md';
import jsonData from '../Data.json';
import { base_url } from '../config/config';
import { useLocation } from 'react-router-dom'
import { MarketContext } from '../Marketcontextapi';
import { IoMdArrowDropdown } from 'react-icons/io';
import TradeTable from './TradeTable';
const TradeMid1 = ({ symbol }) => {
    // console.log('symbol: ', symbol);
    const { data, error, loading } = useContext(MarketContext);
    // const [error, setError] = useState(null);
    // console.log('data-->tradepage', data);
    console.log({data});
    

    const filteredData = data.filter(item => item.symbol === symbol);


    // useEffect(()=>{

    // },[symbol,filteredData])
    const DropdownBtn = () => {
        let dbody = document.getElementById('dropdown-body');
        dbody.classList.toggle("add-dropdown-body")
    }

    return (
        <>

            <div className='card trade-mid-card'>

                {
                    filteredData?.map((item, index) => {
                        return (
                            <>
                                <div div className='t-mid-header'>
                                    <div className='t-mid-left'>
                                        <img src={item.image} alt='h-image' />
                                        <span className='uc-name'>{item.name}</span>
                                        <span className='uc-name-color'>{item.symbol}</span>
                                    </div>
                                    <div className='t-mid-right'>
                                        <span className={item.price_change_percentage_24h > 0 ? 'tm-right-value1' : 'tm-right-value1-red'}>{item.price ? ((item.price).toFixed(2)) : 0}</span>


                                        {
                                            item.price_change_percentage_24h > 0 ? (<span className='green-value'>{item.price_change_percentage_24h}% <IoTrendingUpOutline className='up-ic' /></span>)
                                                :
                                                (<span className='red-value'>{item.price_change_percentage_24h}%<IoTrendingDownOutline className='down-ic' /></span>)
                                        }


                                        <div class="dropdown">
                                            <a class="btn mt-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                               {symbol}
                                            </a>

                                            <ul class="dropdown-menu mt-dropdown-body" aria-labelledby="dropdownMenuLink">
                                              
                                                <TradeTable symbol={symbol ? symbol : 'BNB'} />
                                            </ul>
                                        </div>


                                    </div>


                                </div>






                            </>
                        )
                    })
                }
            </div >

        </>
    )
}

export default TradeMid1


