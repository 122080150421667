
// export const market_url = `https://api.coingecko.com/api/v3`
// 
// export const base_url = `https://api.bitnetto.io/api`

// export const APK_URL = `https://glcasset.com/uploads/bitnetto%20v1.0.3.apk`

// export const APP_STORE_URL = `https://rbcasset.com/uploads/Bitnetto%20v1.0.3%20(1).apk`

export const APP_STORE_URL = `https://apps.apple.com/us/app/bitnetto/id6504752389`

export const PLAY_STORE_URL = `https://rbcasset.com/uploads/Bitnetto_app.apk`

export const base_url = `https://api.bitnetto.io/api`
export const socket_url = `https://api.bitnetto.io`

// export const base_url = `https://bitnetto.metagainfx.com/api`

// export const base_url = `https://bitnetto.metagainfx.com/api`
// export const base_url = `https://api.bitnettos.io/api`
// 
// export const base_url = `http://192.168.1.10:8000/api`
// export const socket_url = `http://192.168.1.10:8000`

