
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import countryData from 'country-data';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import KycTab from './KycTab';
import ProfileSidebar from '../ProfilePages/ProfileSidebar';
import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';

const KycPage = () => {
    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#0000003b" : null,
              
                fontSize: '13px'
            };
        }
    };
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');

    const [countdown, setCountdown] = useState(0);
    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);
   
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    

    const options = countryData.countries.all.map(country => ({
        value: country.name,
        label: country.name
    }));

    const getUserDetail = async () => {
        const response = await fetch(`${base_url}/v1/userProfile`, {
            method: 'get',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (data) {
            setUserData(data.user);
            if (data.user) {
                setFirstname(data.user.firstname || '');
                setLastname(data.user.lastname || '');
                setAddress(data.user.address || ''); 
                const userCountry = options.find(option => option.value === data.user.country);
                setSelectedCountry(userCountry || '');   
               
            }

        } else {
            setUserData('Pending');
        }
    };

    useEffect(() => {
        getUserDetail();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            firstname,
            lastname,
            country: selectedCountry?.value,
            address,
        };
    
        if (!formData.firstname || !formData.lastname || !formData.address) {
            toast.dismiss();
            toast.error('All Fields Are Required');
            return;
        }
        if (!selectedCountry) {
            toast.dismiss();
            toast.error('Please select a country.');
            return;
        }
        if (userData && userData.country && !selectedCountry && userData.country !== selectedCountry?.value) {
            toast.dismiss();
            toast.error('You have already selected a country. Please select it again.');
            return;
        }
 

    
        setLoading(true);
    
        try {
            const response = await fetch(`${base_url}/update_basic_details`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (data.success === 1) {
                
                toast.dismiss();
                toast.success(data?.message);
                setLoading(false);
                navigate('/UserPan');
                getUserDetail();
            } else {
                toast.dismiss();
                toast.error(data?.message);
                setLoading(true);
               
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            toast.dismiss();
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };
    
    



    return (
        <>
      
                <section className='kyc-profile-main'>
            <div className='container'>
                <div className='row'>
                        <div className='col-md-13 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar/>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                            <ProfileTopHeader />

                            <div className='kyc-01'>
                            <div className='kyc-01-heading'>
                            <span className="number">01</span>
                            <div className=''>
                                <h5>User Personal Details</h5>
                                <p>To Verify Your Identity, Please Upload Your Personal Details</p>
                            </div>
                        </div>
                
                    <div className='kyc-inner-row'>
                        <div className='kyc-inner-page'>
                            <form className='row' onSubmit={handleSubmit}>
                                <div className='form-group col-md-12'>
                                    <label>First Name</label>
                                    <input type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder='First Name' className='form-control mt-2' />
                                </div>
                                <div className='form-group col-md-12 mt-4'>
                                    <label>Last Name</label>
                                    <input type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder='Last Name' className='form-control mt-2' />
                                </div>
                                <div className='form-group col-md-12 mt-4'>
                                    <label>Country</label>
                                    <Select
                                        value={selectedCountry}
                                        onChange={selectedOption => setSelectedCountry(selectedOption)}
                                        styles={colourStyles}
                                        options={options}
                                        placeholder="Select the country"
                                        classNamePrefix="c-select"
                                        formatOptionLabel={option => (
                                            <div className="cselect-option">
                                                {
                                                    option.label
                                                }
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className='form-group col-md-12 mt-4'>
                                    <label>Address</label>
                                    <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder='Address' className='form-control mt-2' />
                                </div>
                                <div className='form-group col-md-12 mt-5 text-center'>
                                    <button type="submit" className='btn kyc-btn'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
   
            </div>
                            </div>
                        </div>
                </div>
            </div>
   </section>  
        </>
    );
};

export default KycPage;


// import idf from '../../assets/img/f_id.png';
// import idb from '../../assets/img/b_id.png';
// import { useNavigate } from 'react-router-dom';
// import Select from 'react-select';
// import countryData from 'country-data';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';

// const KycPage = () => {
//     const [loading, setLoading] = useState(false);
//     const [country, setCountry] = useState('');
//     const [selectedCountry, setSelectedCountry] = useState(null);
//     const [submissionSuccess, setSubmissionSuccess] = useState(false); 
//     const navigate = useNavigate();
//     const options = countryData.countries.all.map(country => ({
//         value: country.name,
//         label: country.name
//     }));

   
//     const fetchData = async (formData) => {
//         const token = localStorage.getItem("token");
//         try {
//             const response = await fetch(`${base_url}/update_basic_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(formData),
//             });
//             const data = await response.json();
//             if (data.success === 1) {
//                // console.log('Data successfully submitted:', data);
//                 toast.success(data?.message);
//                 setSubmissionSuccess(true);
//             } else {
//                 toast.error(data?.message);
//             }
//         } catch (error) {
//             console.error( error);
//             toast.error( error);
//         } finally {
//             setLoading(false);
//         }
//     };
    

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         const formData = {
//             firstname: event.target.elements.firstname.value,
//             lastname: event.target.elements.lastname.value,
//             country: selectedCountry.value,
//             address: event.target.elements.address.value,
//         };
//         setLoading(true);
//         await fetchData(formData);
//     };

//     const handleCountryChange = selectedOption => {
//         setSelectedCountry(selectedOption);
//     };

//     useEffect(() => {
//         if (submissionSuccess) {
//             // toast.success('Data submitted successfully!');
//             navigate('/UserPan');
//         }
//     }, [submissionSuccess]);

//     return (
//         <>
//             <section className='kyc-01'>
//                 <div className="container">
//                     <div className='kyc-inner-row'>
//                         <div className='kyc-page-title'>
//                             <h3>Profile</h3>
//                             <p>Personal Information</p>
//                         </div>
//                         <div className='kyc-inner-page'>
//                             <form className='row' onSubmit={handleSubmit}>
//                                 <div className='form-group col-md-12'>
//                                     <label>First Name</label>
//                                     <input type="text" name="firstname" placeholder='First Name' className='form-control mt-2' />
//                                 </div>
//                                 <div className='form-group col-md-12 mt-4'>
//                                     <label>Last Name</label>
//                                     <input type="text" name="lastname" placeholder='Last Name' className='form-control mt-2' />
//                                 </div>
//                                 <div className='form-group col-md-12 mt-4'>
//                                     <label>Country</label>
//                                     <Select
//                                         value={selectedCountry}
//                                         onChange={handleCountryChange}
//                                         options={options}
//                                     />
//                                 </div>
//                                 <div className='form-group col-md-12 mt-4'>
//                                     <label>Address</label>
//                                     <input type="text" name="address" placeholder='Address' className='form-control mt-2' />
//                                 </div>
//                                 <div className='form-group col-md-12 mt-5 text-center'>
//                                     <button type="submit" className='btn kyc-btn'>Submit</button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default KycPage;