import React, { useState, useEffect } from 'react';
import InnerHeader from './InnerHeader';
import Select from 'react-select';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import WithdrawList from './WithdrawList';

import DepsoitImage from '../../assets/img/withdrawl2.png'

const Withdraw = () => {
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [coinOptions, setCoinOptions] = useState([]);
    const [networkOptions, setNetworkOptions] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [otpButtonDisabled, setOtpButtonDisabled] = useState(false); // State to manage whether OTP button is disabled
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [amount, setAmount] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [tokenId, setTokenId] = useState();

    const colourStyles = {
        option: (styles, { data, isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#0000003b" : null,
                color: "#333333",
                fontSize: '13px'
            };
        }
    };


    useEffect(() => {
        fetchData();
    }, [selectedCoin]);


    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token");
            const coinResponse = await fetch(`${base_url}/withraw_tokens`, {
                headers: {
                    Authorization: token,
                },
            });
            if (coinResponse.status === 401) {
                console.log("Unauthorized access detected!");
                return;
            }
            const coinData = await coinResponse.json();
            const coinOptions = coinData.withraw_tokens.map((coin) => ({
                value: coin._id,
                label: coin.coinName,
                image: coin.image,
            }));
            setCoinOptions(coinOptions);





            if (selectedCoin) {
                console.error("Error fetching data:", error);
                const depositTokenResponse = await fetch(`${base_url}/deposit_token_details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    body: JSON.stringify({
                        tokenID: selectedCoin?.value,
                    }),
                });
                const depositTokenData = await depositTokenResponse.json();
                // console.log("depositTokenData", depositTokenData);
                if (depositTokenData.success === 1) {
                    const tokenDetails = depositTokenData.tokenDetails[0];
                    if (tokenDetails) {
                        const networkDetails = tokenDetails.networkDetails;
                        const networkOptions = networkDetails.map((network) => ({
                            value: network._id,
                            label: network.Network,
                        }))

                        // console.log('depositTokenData.tokenDetails[0].networkDetails[0]', depositTokenData.tokenDetails[0].networkDetails[0]);
                        setNetworkOptions(networkOptions);
                        setSelectedNetwork(depositTokenData.tokenDetails[0].networkDetails[0].Network)
                        setTokenId(depositTokenData.tokenDetails[0].networkDetails[0]._id)

                    }
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // console.log({ selectedNetwork, tokenId });

    // const handleSendOtp = async () => {
    //     try {
    //         const token = localStorage.getItem("token");
    //         const response = await fetch(`${base_url}/withdrawotp`, {
    //             method: "POST",
    //             headers: {
    //                 Authorization: token,
    //             },
    //             body: JSON.stringify({}),
    //         });
    //         const data = await response.json();
    //         console.log('data---',data);
    //         if (data.success === 1) {
    //             // Set OTP in state if you need to use it somewhere else
    //             // setOtp(data.otp);
    //             console.log('data======================',data);
    //             toast.dismiss();
    //             toast.success(data.message);
    //         } else {
    //             setError("Failed to send OTP. Please try again.");
    //         }
    //     } catch (error) {
    //         setError("Failed to send OTP. Please try again.");
    //         console.error("Error sending OTP:", error);
    //     }
    // };

    const handleSendOtp = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(`${base_url}/withdrawotp`, {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: JSON.stringify({}),
            });
            const data = await response.json();
            if (data.success === 1) {
                toast.dismiss();
                toast.success(data.message);

                setOtpButtonDisabled(true);
                setEmailCountdown(60);
                const timer = setInterval(() => {
                    setEmailCountdown((prevCountdown) => {
                        if (prevCountdown === 0) {
                            clearInterval(timer);
                            setOtpButtonDisabled(false);
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
            } else {
                setError("Failed to send OTP. Please try again.");
            }
        } catch (error) {
            setError("Failed to send OTP. Please try again.");
            console.error("Error sending OTP:", error);
        }
    };

    const handleWithdrawRequest = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Check if selectedCoin or selectedNetwork is empty
        if (!selectedCoin || !selectedNetwork) {
            toast.dismiss();
            toast.error("Please select a coin and a network.");
            return;
        }

        // const amountInput = document.getElementById("amountInput");
        // const amount = amountInput ? parseFloat(amountInput.value) : null;

        // Check if amount is empty, 0, or not a valid number
        if (!amount || amount === 0 || isNaN(amount)) {
            toast.dismiss();
            toast.error("Amount must be a valid number and greater than 0.");
            return;
        }

        // const walletAddressInput = document.getElementById("walletAddressInput");
        // const walletAddress = walletAddressInput ? walletAddressInput.value : '';


        if (!walletAddress) {
            toast.dismiss();
            toast.error("Please enter your wallet address.");
            return;
        }

        // const otpInput = document.getElementById("otpInput");
        // const otpValue = otpInput ? parseInt(otpInput.value) : null;


        if (!otpValue || isNaN(otpValue)) {
            toast.dismiss();
            toast.error("pls enter the otp.");
            return;
        }
        try {
            let body = {
                token_id: selectedCoin.value,
                network_id: tokenId,
                wallet_address: walletAddress,
                amount: amount,
                otp: otpValue,
            }
            // console.log('body', body);
            const token = localStorage.getItem("token");
            const response = await fetch(`${base_url}/withdrawrequest`, {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });
            const data = await response.json();
            // console.log("Response:", data);

            if (data.success === 1) {
                // console.log("Withdrawal successful");
                toast.dismiss();
                toast.success("Withdrawal successful");
                setSelectedCoin(null);
                setSelectedNetwork('');
                setEmailCountdown(0);
                setOtpButtonDisabled(false);
                setAmount('')
                setWalletAddress('')
                setOtpValue('')
            } else {
                if (data.otp_error) {
                    setError(data.message);
                } else {
                    setError("Error in withdrawal. Please try again.");
                    toast.dismiss();
                    toast.error(data.message);
                }
            }
        } catch (error) {
            console.error("Error in withdrawal:", error);
            setError("Error in withdrawal. Please try again.");
        }
    };
    // console.log({ amount });

    return (
        <>
            <InnerHeader />
            <section className='deposit-page-main'>
                <div className='container'>
                    <div className='de-page-title'>
                        <h2>Withdrawal </h2>
                        {/* <span>minimum 2 USDT is required in order to initiate the transaction</span> */}
                    </div>
                    <div className='dep-row'>
                        <div className='dep-left'>
                            <form onSubmit={(event) => handleWithdrawRequest(event)}>
                                <div className='dep-left-form'>
                                    <div className='dep-inner-flex'>
                                        <div className='label-div mt-1'><span>Select a Coin</span></div>
                                        <div className='form-group input-div'>
                                            <label>Coin</label>
                                            <Select
                                                value={selectedCoin}
                                                onChange={(coin) => { setSelectedCoin(coin) }}
                                                options={coinOptions}
                                                styles={colourStyles}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        <img src={option.image} alt="coin" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Select Network</span></div>
                                        <div className='form-group input-div'>
                                            <label>Network</label>
                                            <div className='form-group  input-div-1'>
                                                <input id="amountInput" type="text" className='form-control w-100' value={selectedNetwork && selectedNetwork} autoComplete='off' />
                                            </div>

                                            {/* <Select
                                                value={selectedNetwork}
                                                onChange={(network) => setSelectedNetwork(network)}
                                                styles={colourStyles}
                                                options={networkOptions}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        {option.label}
                                                    </div>
                                                )}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Enter Your Amount</span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <label>Amount</label>
                                            <input id="amountInput" type="number" className='form-control w-100' min='0' value={amount} autoComplete='off' onChange={(e) => { setAmount(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Enter Your Wallet Address</span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <label>Wallet Address</label>
                                            <input id="walletAddressInput" type="text" className='form-control w-100' value={walletAddress} onChange={(e) => { setWalletAddress(e.target.value) }} autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Enter OTP</span></div>
                                        <div className='form-group input-div input-div-1 input-div-flex'>
                                            <div className='input-div'>
                                                <label>Enter OTP</label>
                                                <input id="otpInput" type="number" className='form-control w-100' min='0' value={otpValue} onChange={(e) => { setOtpValue(e.target.value) }} autoComplete='off' />
                                            </div>
                                            <div className='otp-btn-div'>
                                                <button type='button' className='btn otp-btn withraw-otp' onClick={handleSendOtp} disabled={otpButtonDisabled}>
                                                    {otpButtonDisabled ? `Resend OTP  ${emailCountdown} seconds` : 'Send OTP'}
                                                </button>
                                                {/* <button type='button' className='btn otp-btn withraw-otp' onClick={handleSendOtp}>Send OTP</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-4'>
                                        <div className='label-div mt-1'><span></span></div>
                                        <div className='form-group input-div input-div-1'>
                                            <button type="submit" className='btn kyc-btn'>Submit</button>
                                        </div>
                                    </div>

                                    <div className='dep-inner-flex mt-4'>
                                        <div className='label-div mt-1'></div>
                                        <div className='form-group input-div input-div-1'>
                                            <div className='mini'>
                                                <p className=''>Minimum 2 USDT is required in order to initiate the transaction</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='dep-inner-flex mt-4'>
                                        <div className='label-div mt-1'><span></span></div>
                                        <div className='form-group input-div'>
                                            <div className='w-footer-main'>
                                                <div className='w-footer-left'>
                                                    <li>Available Balance:</li>
                                                    <p>0.00 USDT</p>
                                                    <li>Fees:</li>
                                                    <p> 0.10-35.00 USDT</p>
                                                </div>
                                                <div className='w-footer-left'>
                                                    <li>Minimum Withdrawal:</li>
                                                    <p>1.00 USDT</p>
                                                    <li>Remaining daily withdrawal amount:</li>
                                                    <p> 1 BTC</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='dep-right'>
                            {/* <h4>FAQ</h4>
                            <div className='paragraph-div'>
                                <ul>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>How do I Withdraw crypto onto my Bitnatto account?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I didn't receive my deposits or I deposit to an incorrect address?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I deposit the wrong crypto?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I forgot to specify the Memo, Tag, or Message for my deposit?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I mistakenly deposit through the BSC or BEP20 network and did not receive the deposit?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What are the common deposit networks?</span></li>
                                </ul>
                            </div> */}
                            <img src={DepsoitImage} alt="Deposit-image" className='w-100' />
                        </div>
                    </div>
                    <WithdrawList />
                </div >
            </section >
        </>
    );
};

export default Withdraw;
