import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";

import './assets/css/style.css';
import './assets/css/homepage.css';
import './assets/css/market.css';
import './assets/css/login-page.css';
import './assets/css/template.css';
import './assets/css/dashboard.css';
import './assets/css/desposit.css';
import './assets/css/user-profile.css';
import { MarketsProvider } from './Marketcontextapi';
import { ProfileProvider } from './Profilecontextapi'
import UserDeals from './userpages/transfar-page/UserDeals';
import toast, { Toaster } from 'react-hot-toast';
// import { ThemeProvider } from './ThemeContext';
import Page from "./Pages/page"
import Header from "./Header-Footer/Header";
import Footer from "./Header-Footer/Footer";
import HomePage from './Pages/Homepage/HamePage';
import Markets from "./Pages/Market.js/Markets";
import Convert from "./Pages/Market.js/Convert";
import Signup from "./Auth/Signup";
import Login from "./Auth/Login";
import UserHeader from "./Header-Footer/UserHeader";
import UserFooter from "./Header-Footer/UserFooter";
import About from './Pages/About/About';
import Dashboard from "./userpages/Dashboard";
import Carre from './Pages/carrer/Carre';
import Faq from './Pages/More/Faq';
import Support from './Pages/More/Support';
import Security from './Pages/More/Security';
// import Earn from "./Pages/Earn/Earn";
import Overview from "./userpages/Overview-page/Overview";
import KycPage from "./userpages/Kyc/KycPage";
import MainAccount from "./userpages/Overview-page/MainAccount";
import Forget from './Auth/Forget ';
import UserKyc from './userpages/Kyc/UserKyc';
import Deposit from './userpages/transfar-page/Deposit';
import Downloads from './Pages/More/Downloads'
import ChangePassword from './userpages/ChangePassword';
import Withdraw from './userpages/transfar-page/Withdraw';
import Referral from './Pages/Market.js/Referral'
import WithdrawHistory from './userpages/transfar-page/WithdrawHistory';
import DepositHistory from './userpages/transfar-page/DepositHistory';
import TradingAccount from './userpages/Overview-page/TradingAccount';
import FundingAccount from './userpages/Overview-page/FundingAccount';
import UserPan from './userpages/Kyc/UserPan';
import Fees from './Pages/More/Fees';
import Coin from './Pages/More/Coin';
import Trade from './Trade/Trade';
import UserSelfie from './userpages/Kyc/UserSelfie';
import Transfer from './userpages/transfar-page/Transfer';
// import UserProfile from './userpages/Kyc/UserProfile';
import Auth from './Components/Auth';
import KycProfileMain from './userpages/ProfilePages/KycProfileMain';
import UserKycProfile from './userpages/ProfilePages/UserKycProfile';
import Comming from './Pages/More/Comming-soon';
import SmoothScroll from './SmoothScroll';
import ReferralLink from './userpages/ProfilePages/ReferralLink';
import ReferralBonus from './userpages/ProfilePages/ReferralBonus';
// import BinanceWebSocket from './Trade/BinanceWebSocket';
import GetUserDetail from './ContextApi/GetUserDetail'
import { OrderProvider } from "./Trade/OrderContext";
import TransactionHistory from './userpages/Overview-page/TransactionHistory';
import Swap from './userpages/Overview-page/Swap';
import UserVerify from './userpages/ProfilePages/UserVerify';
import CustomChart from './Trade/CustomChart';
import Staking from './userpages/Overview-page/Staking';
import PopUp from './Components/PopUp';
import TokenHistort from './userpages/Overview-page/TokenHistort';
import TradeOrderHistory from './Trade/TradeOrderHistory';
import OffSet from './Components/OffSet';
import BugReport from './userpages/ProfilePages/BugReport';
import Sidebar from './Components/Sidebar';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
function App() {

  const token = localStorage.getItem('token');

  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'dark');
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleMode = () => {
    setTheme(prevTheme => (prevTheme === 'dark' ? 'light' : 'dark'));
  };


  const AuthLogin = () => {
    return (
      <>
        <Outlet />
      </>
    )
  }

  const Public = () => {
    return (
      <>
        <Header toggleMode={toggleMode} theme={theme} />
        <Outlet />
        <Footer theme={theme} />
      </>
    )
  }

  const TradePage = () => {
    return (
      <>
        <Header toggleMode={toggleMode} theme={theme} />
        <Outlet />
      </>
    )
  }

  const UserPage = () => {
    return (
      <>
        <UserHeader toggleMode={toggleMode} theme={theme} />
        <Outlet />
        <UserFooter theme={theme} />
      </>
    )
  }

  return (
    <>
  <OrderProvider>
      <MarketsProvider>

        <Router>
          <ProfileProvider>
            <SmoothScroll />
            <Routes>
              <Route element={<TradePage />}>
                <Route path="/trade" element={<Trade theme={theme} />} />

              </Route>
              <Route element={<AuthLogin />}>
                <Route path="/Login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forget" element={<Forget />} />
                <Route path="/sidebar" element={<Sidebar />} />
              </Route>

              <Route element={<Public />}>
                <Route path="/Comming" element={<Comming />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/market" element={<Markets />} />
                <Route path="/convert" element={<Comming />} />
                {/* <Route path="/earn" element={<Earn />} /> */}
                <Route path="/About" element={<About />} />
                <Route path="/Carrer" element={<Carre />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/support" element={<Support />} />
                <Route path="/policy" element={<PrivacyPolicy />} />
                <Route path="/security" element={<Security />} />
                <Route path="/download" element={<Downloads />} />
                <Route path="/referral" element={<Referral />} />
                <Route path="/fees" element={<Fees />} />
                <Route path="/coin" element={<Coin />} />
                {/* <Route path="/websocket" element={<BinanceWebSocket />} /> */}
                <Route path="/customchart" element={<CustomChart theme={theme} />} />
                <Route path="/tradeorderhistory" element={<TradeOrderHistory theme={theme} />} />
                <Route path="/offset" element={<OffSet theme={theme} />} />

              </Route>
              <Route element={<Auth />}>
                <Route element={<UserPage />}>
                  <Route path='/userdeals' element={<UserDeals />}></Route>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/overview" element={<Overview />} />
                  <Route path="/mainaccount" element={<MainAccount />} />
                  <Route path="/tradingaccount" element={<TradingAccount />} />
                  <Route path="/fundingaccount" element={<FundingAccount />} />
                  <Route path="/transactionshistort" element={<TransactionHistory />} />
                  <Route path="/swap" element={<Swap />} />

                  <Route path="/userdetail" element={<KycPage />} />
                  <Route path="/UserPan" element={<UserPan />} />
                  <Route path="/userkyc" element={<UserKyc />} />
                  <Route path="/userselfie" element={<UserSelfie />} />
                  <Route path="/changepassword" element={<ChangePassword />} />
                  <Route path="/deposit" element={<Deposit theme={theme} />} />
                  <Route path="/withdraw" element={<Withdraw />} />
                  <Route path="/transfer" element={<Transfer />} />
                  <Route path="/withdrawhistory" element={<WithdrawHistory />} />
                  <Route path="/deposithistory" element={<DepositHistory />} />
                  <Route path="/kycprofiledetail" element={<KycProfileMain />} />
                  <Route path="/userkycprofile" element={<UserKycProfile />} />
                  <Route path="/referral-link" element={<ReferralLink />} />
                  <Route path="/referral-bonus" element={<ReferralBonus />} />
                  <Route path="/comming" element={<Comming />} />
                  <Route path="/user-verify" element={<UserVerify />} />
                  <Route path="/bug_reports" element={<BugReport />} />
                  <Route path="/staking" element={<Staking />} />
                  <Route path="/tokenhistory" element={<TokenHistort />} />
                  {/* <Route path="/popup" element={<PopUp/>} /> */}
                </Route>
              </Route>

            </Routes>
            <Toaster />
          </ProfileProvider>
        </Router>

      </MarketsProvider>

      </OrderProvider>
    </>

  );
}

export default App;
