// 

import React from 'react';
import comming from '../../assets/icons/comming-soon.jpg'

const Comming = () => {
  return (
    <div className='Container'>
      <div className='row'>
        <div className='col-lg-12,col-sm-12'>
          <div className='vi w-100'>
         
            <img src={comming} alt='comming soon ' className='w-100' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comming;
