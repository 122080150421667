import React, { useContext, useState } from 'react';
import { MarketContext } from '../Marketcontextapi';
import { MdOutlineSearch } from 'react-icons/md';
import { IoTrendingDownOutline, IoTrendingUpOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { base_url } from '../config/config';
import coinImg from '../assets/icons/bnb.png';
import { ColorRing } from 'react-loader-spinner'; 

const TradeTable = ({ symbol }) => {
    // const [data, setData] = useState([]);
    // const [error, setError] = useState(null);
    const { data, error, loading } = useContext(MarketContext); 
    const [searchQuery, setSearchQuery] = useState('');
    const theme =localStorage.getItem('theme');
    // const fetchData = async () => {
    //     try {
    //         const response = await fetch(`${base_url}/mainMarket?limit=11`);
    //         if (!response.ok) {
    //             throw new Error('Failed to fetch data');
    //         }
    //         const responseData = await response.json();
    //         setData(responseData.data);
    //     } catch (error) {
    //         setError(error.message);
    //     }
    // };

    // useEffect(() => {
        
    //     fetchData();
    //     const intervalId = setInterval(fetchData, 5000);
    //     return () => clearInterval(intervalId);
    // }, []);

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter(item => {
        const searchTerm = searchQuery.toLowerCase();
        return (
            item.name.toLowerCase().includes(searchTerm) ||
            item.symbol.toLowerCase().includes(searchTerm)
        );
    });
    if (loading) {
        const spinnerColors = theme === 'dark' ? ['#fff', '#fff', '#fff', '#fff', '#fff'] : ['#000', '#000', '#000', '#000', '#000'];
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <ColorRing color={theme === 'dark' ? '#fff' : '#000'} height={80} width={80} colors={spinnerColors} wrapperClass="spinner1" />
            </div>
        );
    }
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <div>
                <div className='slider-top-srh'>
                    <input type="search" placeholder='Search Coin Name or Symbol' className='w-100' value={searchQuery} onChange={handleSearchInputChange} />
                    <MdOutlineSearch className='srh-icon' />
                </div>

                <div className='trade-table1 table-scroll1'>
                    <table className="table tradeing-table-row">
                        <thead>
                            <tr className='t-tr-row'>
                                <th scope="col">Coin Name</th>
                                <th scope="col">Coin Price (USDT)</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredData.map((item, index) => (
                                <NavLink key={index} to={`/trade?symbol=${item.symbol}`} className="tradetable-navlink">
                                    <tr className='t-trbody-row'>
                                        <td className='col-td1'>
                                            <div className='table-image'>
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div className='col-td1-text'>
                                                <li><span className='coin-name'>
                                                    {item.name}
                                                </span></li>
                                                <li className='mt-1'><span className='td-btn'>
                                                    {item.symbol}
                                                </span></li>
                                            </div>
                                        </td>
                                        <td className='col-td2'>
                                            <li><span className='td-value1'>
                                                {item.price ? (item.price).toFixed(3) : '0'}
                                            </span></li>
                                            <li>
                                                <span className={item.percent_change_1h > 0 ? 'td-value-up' : 'td-value-down'}>
                                                    {item.percent_change_1h ? (item.percent_change_1h).toFixed(3) : '0'}
                                                </span>
                                                <span>{item.price_change_percentage_24h > 0 ? <IoTrendingUpOutline className='up-ic' /> : <IoTrendingDownOutline className='down-ic' />}</span>
                                            </li>
                                        </td>
                                    </tr>
                                </NavLink>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default TradeTable;
