import React from 'react'
import UserHeader from '../Header-Footer/UserHeader';
import UserFooter from '../Header-Footer/UserFooter';
import {Navigate, Outlet } from "react-router-dom";

const Auth = () => {
    const token = localStorage.getItem('token');
    // if (token) {
    //     return (
    //         <>
        
    //         { <Outlet/> }
           
    //         </>
    //     )
    // }
    // else{
    //     return <Navigate to="/"/>
    // }
    return token?<Outlet/>:<Navigate to="/"/>

}

export default Auth