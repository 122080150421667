import React from 'react'
import { Modal } from 'react-responsive-modal';

const PopModal = ({ open, onCloseModal }) => {


    return (
        <>
            <div className='transfer-modals'>
                <Modal open={open} onClose={onCloseModal}>
                    <div className='modal-content-mains'>
                        <div className='modal-headers'>
                            <p>
                                By logging into your account, you agree to our terms and conditions. The Company takes no responsibility and will not be
                                liable for any losses,damages, or claims arising from the use of its services. The Company also reserves the right to modify
                                or discontinue any portion of its services at any time, without liability to you or prior notice.

                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        </>






    )
}

export default PopModal


















