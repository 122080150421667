import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const OffSet = () =>  {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };

    return (
        <div>
            <a className="btn btn-primary" onClick={() => setShowOffcanvas(true)} role="button" aria-controls="offcanvasExample">
                Link with href
            </a>
           
            <div className={`offcanvas offcanvas-start${showOffcanvas ? ' show' : ''}`} tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
                    <button type="button" className="btn-close text-reset" onClick={handleCloseOffcanvas} aria-label="Close" />
                </div>
                <div className="offcanvas-body">
                    <div>
                        Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                    </div>
                    <div className="dropdown mt-3">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                            Dropdown button
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a className="dropdown-item"  >Action</a></li>
                            <li><a className="dropdown-item" >Another action</a></li>
                            <li><NavLink className="dropdown-item" to="/" onClick={handleCloseOffcanvas}>Something else here</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OffSet