import React, { useEffect, useState } from 'react'
import c1 from '../../assets/icons/m1-icon.png';
import m1 from '../../assets/icons/m1-icon.png';
import m2 from '../../assets/icons/bnb.png';
import m3 from '../../assets/icons/btc.png';
import m4 from '../../assets/icons/m2-icon.png';
import { MdOutlineSearch } from 'react-icons/md';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import OverHeader from './OverHeader';
import { base_url } from '../../config/config';
import Skeleton from 'react-loading-skeleton';
import dateFormat from 'dateformat';


const TransactionHistory = () => {
    const [funding, setFunding] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10); // Limit is fixed at 10 per page
    const [total, setTotal] = useState(0);
    const [gData, setGData] = useState();
    const [fundData, setFundData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('')
    // console.log("search", search)


    const TransactionsHistory = async (skip = 0) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/all_token_deposit_transactions`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit,
                skip,
                transactionType: "transfer"
            })
        });
        const data = await response.json();
        setTotal(data.total)
        if (data) {
            setFundData(data.data)
            setLoading(false);
        } else {
            console.error('Error:', response.status);
        }
    };




    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        TransactionsHistory(skip);
    }, [currentPage, limit]);


    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const capitalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/[_-]/g, ' ') // Replace underscores or hyphens with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

    };

    return (
        <>

            <OverHeader />
            <section>
                <div className='container'>
                    <div className='overview-tab-content'>
                        <div className='over-content-01'>
                            <div className='over-01-left'>
                                <div className='over-pagetitle-01'>
                                    <h2>Transfer History</h2>
                                </div>
                                <div className='d-gap d-flex align-items-center'>


                                </div>
                            </div>
                            <div className='over-01-right'>

                                <div className=''></div>
                                <div className='over-01-innerdiv'>
                                    {/* <div className='form-group form-group-flex'>
                                        <input id="bls" type="checkbox" /><label htmlFor='bls' className='ms-2'>Hide Zero Balances</label>
                                    </div> */}
                                    <div className='form-group'>
                                        <div className='slider-top-srh'>
                                            <input type="search" placeholder='Search Coin Name' onChange={(e) => setSearch(e.target.value)} />
                                            <MdOutlineSearch className='srh-icon' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='market-table'>
                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Token Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">From</th>
                                            <th scope="col">To</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {fundData.length > 0 ? (
                                            fundData.filter((item) => {
                                                return search.toUpperCase() === ''
                                                    || item.tokenName.toUpperCase().includes(search.toUpperCase());
                                            })
                                                .map((item, index) => (
                                                    <tr>
                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td className='td-1'>
                                                            <div className='markit-td1'>
                                                                <span className='coin-name'>{item.tokenName}</span>
                                                                <span className='coin-tag-name'>{item.tokenSymbol}</span>
                                                            </div>
                                                        </td>
                                                        <td className={item.amount > 0 ? 'green' : 'red'}>{item?.amount}</td>
                                                        <td>{capitalizeText(item.from)}</td>
                                                        <td>{capitalizeText(item.to)}</td>
                                                        <td>{dateFormat(item.createdAt, "dd-mm-yy,hh:mm:ss TT")}</td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr className='text-center'>
                                                <td colSpan='6'>Data Not Found</td>
                                            </tr>
                                        )}

                                        {/* {fundData.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className='td-1'>
                                                        <div className='markit-td1'>

                                                            <span className='coin-name'>{item.tokenName}</span>
                                                            <span className='coin-tag-name'>{item.tokenSymbol}</span>
                                                        </div>
                                                    </td>
                                                    <td>{item.from}</td>
                                                    <td>{item.to}</td>

                                                    <td className={item.amount > 0 ? 'green' : 'red'}>{item?.amount}</td>
                                                    <td>{item.createdAt}</td>
                                                </tr>
                                            )
                                        })} */}
                                    </tbody>
                                </table>
                                <div className='market-table-footer'>
                                    {fundData.length > 0 ? (
                                        <>
                                            <div className='total-page'>
                                                <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                            </div>
                                            <div className='pagination-div'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                <GrFormPrevious />
                                                            </a>
                                                        </li>

                                                        <li className={`page-item`}>
                                                            <a className="page-link page-link1" >
                                                                {currentPage}
                                                            </a>
                                                        </li>

                                                        <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handleNextPage}>
                                                                <GrFormNext />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </>
                                    ) : (
                                        null
                                    )
                                    }
                                </div>

                                {/* <div className='market-table-footer'>
                                
                                    <div className='total-page'>
                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                        <GrFormPrevious />
                                                    </a>
                                                </li>

                                                <li className={`page-item`}>
                                                    <a className="page-link page-link1" >
                                                        {currentPage}
                                                    </a>
                                                </li>

                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                        <GrFormNext />
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TransactionHistory