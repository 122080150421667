import React, { useEffect, useState } from 'react'
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import dateFormat, { masks } from "dateformat";
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import { NavLink } from 'react-router-dom';
import staking from '../../assets/img/staking.png'

const Staking = () => {
    const [tokenId, setTokenId] = useState('');
    const [amount, setAmount] = useState('');
    const [stakeData, setStakeData] = useState([]);
    const [returnHistory, setReturnHistory] = useState([]);
    const [userBalance, setUserBalance] = useState(null);

    // const [limit, setLimit] = useState(10);
    // const [skip, setSkip] = useState(0);
    // const [total, setTotal] = useState(0);

    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [page] = useState(1)

    const [total1, setTotal1] = useState(0);


    useEffect(() => {
        getStakingData();
        getReturnHistory()
    }, [currentPage, limit, skip]);

    useEffect(() => {
        setSkip((currentPage - 1) * limit);
    }, [currentPage, limit]);

    const totalPages = Math.ceil(total / limit);
    const totalPages1 = Math.ceil(total1 / limit);

    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleNextPage1 = (e) => {
        e.preventDefault();
        if (currentPage < totalPages1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageClick = (e, page) => {
        e.preventDefault();
        setCurrentPage(page);
    };



    const token = localStorage.getItem("token");

    const getToken = async () => {
        const response = await fetch(`${base_url}/stake_able_token`, {
            method: 'get',
        });
        const data = await response.json();
        if (data.success === 1) {
            setTokenId(data?.data[0]?._id)
        }
    };

    const getStakingData = async () => {
        const response = await fetch(`${base_url}/stake_info`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit: limit,
                skip: skip
            })

        });
        const stakingData = await response.json();
        console.log('stakingData', stakingData);
        if (stakingData.success === 1) {
            setStakeData(stakingData.data);
            setTotal(stakingData.total)
        }
    };



    const stakeHandle = async () => {
        console.log('tokenId', tokenId);
        console.log('amount', amount);
        const response = await fetch(`${base_url}/stake_token`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tokenId: tokenId,
                amount: amount,
            })
        });

        const result = await response.json();
        console.log('staking result', result);
        if (result.success === 1) {
            toast.dismiss();
            toast.success(result?.message);
            getStakingData();
            fetchUserBalance();
            setAmount('')
        }
        else {
            toast.dismiss();
            toast.error(result?.message);
        }

    }

    const fetchUserBalance = async () => {
        try {
            const response = await fetch(`${base_url}/useraccountbalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({
                    tokenName: 'USDT',
                    wallet_type: 'main_wallet'
                })
            });

            const responseData = await response.json();
            if (responseData.success) {
                setUserBalance(responseData.balance);
            } else {
                console.error('Failed to fetch user balance:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };


    const getReturnHistory = async () => {
        try {
            const response = await fetch(`${base_url}/stake_return_history`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({
                    limit: limit,
                    skip: skip
                })
            });

            const responseData = await response.json();
            console.log('setReturnHistory--->', responseData);
            if (responseData.success) {
                setReturnHistory(responseData.data);
                setTotal1(responseData.total)

            } else {
                console.error('Failed to fetch user balance:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };



    useEffect(() => {
        getToken();
        // getStakingData();
        fetchUserBalance();

    }, [])

    return (
        <>
            <section className='stake-page-main'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-md-6 col-sm-6 col-12'>
                            <div className='staking-page-title'>
                                <h2>Staking in USDT</h2>
                                <p>Bal: <span>{userBalance} USDT</span></p>
                            </div>
                            <div className='staking-inner-column'>
                                <form>
                                    <div className='form-group'>
                                        <label>Amount</label>
                                        <input type="text" placeholder='Amount' value={amount} onChange={(e) => setAmount(e.target.value)} className='form-control mt-2' />
                                    </div>
                                    <div className='form-group mt-4'>
                                        <button type='button' onClick={stakeHandle} className='btn login-btn'>Stake</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='staking-image text-center'>
                                <img src={staking} alt='' className='w-75' />
                            </div>
                        </div>



                        <div className='staking-tabs'>
                            <nav>
                                <div class="nav nav-tabs staking-tab-ul" id="nav-tab" role="tablist">
                                    <button class="nav-link active staking-nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Staking History</button>
                                    <button class="nav-link staking-nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Return History</button>

                                </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div className='col-md-12'>
                                        <div className='market-table'>
                                            <table className="table mt-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S No.</th>
                                                        <th scope="col">User ID</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Return Rate</th>
                                                        <th scope="col">Day</th>
                                                        <th scope="col">Date & Time</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(stakeData.length > 0) ? (
                                                        stakeData?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                                        {/* <td>{index + 1}</td> */}
                                                                        <td>{item.username}</td>
                                                                        <td>{item.amount}</td>
                                                                        <td>{item.returnRate}</td>
                                                                        <td>{item.days}</td>
                                                                        <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                        )) :
                                                        (<tr className='text-center'>
                                                            <td colSpan='10'>Data Not Found</td>
                                                        </tr>)
                                                    }
                                                    {/* {stakeData?.map((item, index) => {
                                                        // console.log('item', item);
                                                        return (<>
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.username}</td>
                                                                <td>{item.amount}</td>
                                                                <td>{item.returnRate}</td>
                                                                <td>{item.days}</td>
                                                                <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                                            </tr>

                                                        </>)
                                                    })} */}


                                                </tbody>
                                            </table>
                                            {/* <div className='market-table-footer'>
                                                <div className='total-page'>
                                                    <p>1-{count1} Of {count1}</p>
                                                </div>
                                                <div className='pagination-div'>
                                                    <nav aria-label="Page navigation example">
                                                        <ul class="pagination">
                                                            <li class="page-item"><a class="page-link page-link1" href=""><GrFormPrevious /></a></li>
                                                            <li class="page-item"><a class="page-link page-link1" href="">1</a></li>
                                                            <li class="page-item"><a class="page-link page-link1" href=""><GrFormNext /></a></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div> */}

                                            <div className='market-table-footer'>
                                                {stakeData.length > 0 ? (
                                                    <>
                                                        <div className='total-page'>
                                                            <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                                        </div>
                                                        <div className='pagination-div'>
                                                            <nav aria-label="Page navigation example">
                                                                <ul className="pagination">
                                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                        <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                            <GrFormPrevious />
                                                                        </a>
                                                                    </li>

                                                                    <li className={`page-item`}>
                                                                        <a className="page-link page-link1" >
                                                                            {currentPage}
                                                                        </a>
                                                                    </li>

                                                                    <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                                        <a className="page-link page-link1" onClick={handleNextPage}>
                                                                            <GrFormNext />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </>
                                                ) : (
                                                    null
                                                )
                                                }
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className='col-md-12'>
                                        <div className='market-table'>
                                            <table className="table mt-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S No.</th>
                                                        <th scope="col">User ID</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Return Amount</th>
                                                        <th scope="col">Day</th>
                                                        <th scope="col">Date & Time</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(returnHistory.length > 0) ? (
                                                        returnHistory?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.username}</td>
                                                                        <td>{item.amount}</td>
                                                                        <td>{item.returnRate}</td>
                                                                        <td>{item.day}</td>
                                                                        <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                        )) :
                                                        (<tr className='text-center'>
                                                            <td colSpan='10'>Data Not Found</td>
                                                        </tr>)
                                                    }

                                                    {/* {returnHistory?.map((item, index) => {
                                                        // console.log('item', item);
                                                        return (<>
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.username}</td>
                                                                <td>{item.amount}</td>
                                                                <td>{item.returnRate}</td>
                                                                <td>{item.day}</td>
                                                                <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>
                                                            </tr>

                                                        </>)
                                                    })} */}


                                                </tbody>
                                            </table>
                                            <div className='market-table-footer'>
                                                {returnHistory.length > 0 ? (
                                                    <>
                                                        <div className='total-page'>
                                                            <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                                        </div>
                                                        <div className='pagination-div'>
                                                            <nav aria-label="Page navigation example">
                                                                <ul className="pagination">
                                                                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                        <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                            <GrFormPrevious />
                                                                        </a>
                                                                    </li>

                                                                    <li className={`page-item`}>
                                                                        <a className="page-link page-link1" >
                                                                            {currentPage}
                                                                        </a>
                                                                    </li>

                                                                    <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                                        <a className="page-link page-link1" onClick={handleNextPage}>
                                                                            <GrFormNext />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </>
                                                ) : (
                                                    null
                                                )
                                                }
                                            </div>
                                            {/* <div className='market-table-footer'>
                                                <div className='total-page'>
                                                    <p>Page {currentPage} of {totalPages1}</p>
                                                </div>
                                                <div className='pagination-div'>
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination">
                                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                    <GrFormPrevious />
                                                                </a>
                                                            </li>

                                                            <li className={`page-item `}>
                                                                <a className="page-link page-link1" >
                                                                    {currentPage}
                                                                </a>
                                                            </li>

                                                            <li className={`page-item ${currentPage === totalPages1 ? 'disabled' : ''}`}>
                                                                <a className="page-link page-link1" onClick={handleNextPage1}>
                                                                    <GrFormNext />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default Staking