// import React, { useEffect, useState, useContext } from "react";
// import { MarketContext } from "../Marketcontextapi";
// import usd from "../assets/icons/usd.png";
// import { base_url } from "../config/config";
// import toast from "react-hot-toast";
// import { NavLink } from "react-router-dom";
// // import { TradeContext } from './TradeContext';
// const BuySell = ({ symbol: tradeSymbol }) => {
//   const [marketPrice, setMarketPrice] = useState(null);
//   const [limitPrice, setLimitPrice] = useState(null);
//   const [selllimitPrice, setsellLimitPrice] = useState(null);
//   const [userData, setUserData] = useState(null);
//   const { data } = useContext(MarketContext);
//   const [buyLimitAmount, setBuyLimitAmount] = useState("");
//   const [buyAmount, setBuyAmount] = useState("");
//   const [sellLimitAmount, setSellLimitAmount] = useState("");
//   const [sellAmount, setSellAmount] = useState("");
//   const [getId, setGetId] = useState("");
//   const [img, setImg] = useState("");
//   const [symbol, setSymbol] = useState("");
//   const [userBalance, setUserBalance] = useState(null);
//   const [userBalancesell, setUserBalancesell] = useState(null);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [loading, setLoading] = useState(false);
//   // const { tradeData } = useContext(TradeContext);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const filteredData = data.find((item) => item.symbol === tradeSymbol);
//         if (filteredData) {
//           setGetId(filteredData._id);
//           setMarketPrice(filteredData.price);
//           setImg(filteredData.image);
//           setSymbol(filteredData.symbol);
//         } else {
//           console.error("Symbol not found in response data");
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//     getUserDetail();
//     fetchUserBalance();
//     fetchUserBalancesell();
//   }, [data, tradeSymbol]);

//   // useEffect(() => {
    
//   //   if (tradeData.buyPrice) {
//   //     setLimitPrice(tradeData.buyPrice);
//   //   }
//   //   if (tradeData.sellPrice) {
//   //     setsellLimitPrice(tradeData.sellPrice);
//   //   }
//   // }, [tradeData]);
//   // console.log('data', data);
//   const fetchUserBalance = async () => {
//     try {
//       const response = await fetch(`${base_url}/useraccountbalance`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: localStorage.getItem("token"),
//         },
//         body: JSON.stringify({
//           tokenName: "USDT",
//           wallet_type: "trading_wallet",
//         }),
//       });

//       const responseData = await response.json();
//       if (responseData.success) {
//         setUserBalance(responseData.balance);
//       } else {
//         console.error("Failed to fetch user balance:", responseData.message);
//       }
//     } catch (error) {
//       console.error("Error fetching user balance:", error);
//     }
//   };
//   const fetchUserBalancesell = async () => {
//     try {
//       let pastdata = {
//         tokenName: tradeSymbol,
//         wallet_type: "trading_wallet",
//       };
//       const response = await fetch(`${base_url}/useraccountbalance`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: localStorage.getItem("token"),
//         },
//         body: JSON.stringify(pastdata),
//       });
//       // console.log('tokenName', pastdata);

//       const responseData = await response.json();
//       if (responseData.success) {
//         setUserBalancesell(responseData.balance);
//       } else {
//         console.error("Failed to fetch user balance:", responseData.message);
//       }
//     } catch (error) {
//       console.error("Error fetching user balance:", error);
//     }
//   };

//   const handleTrade = async (mode) => {
//     const amount =
//       mode === "BUY" ? parseFloat(buyAmount) : parseFloat(sellAmount);
//     if (isNaN(amount)) {
//       console.error(`Invalid ${mode.toLowerCase()} amount`);
//       return;
//     }
//     const data = {
//       tokenId: getId,
//       mode: mode.toUpperCase(),
//       tradingCurrency: "USDT",
//       // pairCurrency: "662e39e17df77af7d769df5f",
//       pairCurrency: "62c9a988187e31ce104cd317",
//       tokenQuantity: amount,
//       orderType: "MARKET",
//       price: marketPrice,
//     };

//     try {
//       const response = await fetch(`${base_url}/place_trading_order`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: localStorage.getItem("token"),
//         },
//         body: JSON.stringify(data),
//       });

//       const responseData = await response.json();
//       if (responseData.success) {
//       toast.dismiss();
//       toast.success(responseData.message);}
//       else {
//         toast.dismiss();
//         toast.error(responseData.message);
//       }
//     } catch (error) {
//       toast.dismiss();
//       toast.error(`${mode} error`);
//     }
//   };

//   const handleTradeLimit = async (mode) => {
//     if (mode === "BUY") {
//       if (!limitPrice || !buyLimitAmount) {
//         toast.dismiss();
//         toast.error("Please fill all fields for buy limit");
//         return;
//       }
//     }
//     if (mode === "SELL") {
//       if (!selllimitPrice || !sellLimitAmount) {
//         toast.dismiss();
//         toast.error("Please fill all fields for sell limit");
//         return;
//       }
//     }
//     setLoading(true);
//     const amount =
//       mode === "BUY" ? parseFloat(buyLimitAmount) : parseFloat(sellLimitAmount);
//     if (isNaN(amount)) {
//       console.error(`Invalid ${mode.toLowerCase()} amount`);
//       return;
//     }
//     const data = {
//       tokenId: getId,
//       mode: mode.toUpperCase(),
//       tradingCurrency: "USDT",
//       // pairCurrency: "662e39e17df77af7d769df5f",
//       pairCurrency: "62c9a988187e31ce104cd317",
//       tokenQuantity: amount,
//       orderType: "LIMIT",
//       limitprice: mode === "BUY" ? limitPrice : selllimitPrice,
//     };

//     try {
//       const response = await fetch(`${base_url}/place_trading_order`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: localStorage.getItem("token"),
//         },
//         body: JSON.stringify(data),
//       });

//       const responseData = await response.json();
//       if (responseData.success) {
//         toast.dismiss();
//         toast.success(responseData.message);
//         setsellLimitPrice("");
//         setBuyLimitAmount("");
//         setLimitPrice("");
//         setSellLimitAmount("");
//         getUserDetail();
//       } else {
//         toast.dismiss();
//         toast.error(responseData.message);
//       }
//     } catch (error) {
//       toast.dismiss();
//       toast.error(`${mode} error`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const token = localStorage.getItem("token");
//   const getUserDetail = async () => {
//     const response = await fetch(`${base_url}/v1/userProfile`, {
//       method: "get",
//       headers: {
//         Authorization: token,
//         "Content-Type": "application/json",
//       },
//     });
//     const data = await response.json();

//     if (data.user) {
//       setUserData(data.user);
//       setIsLoggedIn(true);
//     } else {
//       setIsLoggedIn(false);
//     }
//   };

//   // const handleTrade = async (mode) => {
//   //     const amount = mode === 'BUY' ? parseFloat(buyAmount) : parseFloat(sellAmount);
//   //     if (isNaN(amount)) {
//   //         console.error(`Invalid ${mode.toLowerCase()} amount`);
//   //         return;
//   //     }
//   //     const data = {
//   //         tokenId: getId,
//   //         mode: mode.toUpperCase(),
//   //         tradingCurrency: "USDT",
//   //         pairCurrency: "662e39e17df77af7d769df5f",
//   //         tokenQuantity: amount,
//   //         orderType: "MARKET",
//   //         price: marketPrice
//   //     };

//   //     try {
//   //         const response = await fetch(`${base_url}/place_trading_order`, {
//   //             method: 'POST',
//   //             headers: {
//   //                 'Content-Type': 'application/json',
//   //                 Authorization: localStorage.getItem('token')
//   //             },
//   //             body: JSON.stringify(data)
//   //         });

//   //         const responseData = await response.json();
//   //         toast.dismiss();
//   //         toast.success(responseData.message);

//   //     } catch (error) {
//   //         toast.dismiss();
//   //         toast.error(`${mode} error`);

//   //     }
//   // };
//   // // console.log("userData", userData);
//   // const token = localStorage.getItem("token");
//   // const getUserDetail = async () => {
//   //     const response = await fetch(`${base_url}/v1/userProfile`, {
//   //         method: 'get',
//   //         headers: {
//   //             Authorization: token,
//   //             'Content-Type': 'application/json'
//   //         }
//   //     });
//   //     const data = await response.json();

//   //     if (data.user) {
//   //         setUserData(data.user);
//   //         setIsLoggedIn(true);
//   //     }
//   //     else {
//   //         setIsLoggedIn(false);
//   //     }
//   // };

//   return (
//     <>
//       <div className="buy-sell-main trade-column2-h">
//         <ul className="nav nav-tabs buysell-tab" id="myTab" role="tablist">
//           <li className="nav-item" role="presentation">
//             <label
//               className="nav-link active buysell-link"
//               id="home-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#home"
//               type="button"
//               role="tab"
//               aria-controls="home"
//               aria-selected="false"
//             >
//               <input id="tab1" type="radio" name="tab" className="" />
//               Limit
//             </label>
//           </li>
//           {/* <li className="nav-item" role="presentation">
//             <label
//               className="nav-link active buysell-link"
//               id="profile-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#profile"
//               type="button"
//               role="tab"
//               aria-controls="profile"
//               aria-selected="true"
//             >
//               <input id="tab2" type="radio" name="tab" />
//               Market
//             </label>
//           </li> */}
//         </ul>
//         <div className="tab-content" id="myTabContent">
//           <div
//             className="tab-pane fade show active "
//             id="home"
//             role="tabpanel"
//             aria-labelledby="home-tab"
//           >
//             <div className="tab-trade-content row">
//               <div className="col-md-6">
//                 <form
//                   className="row"
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleTradeLimit("BUY");
//                   }}
//                 >
//                   <div className="col-md-12">
//                     <div className="main-input-flex">
//                       <div className="form-group">
//                         <label>Limit Price</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={limitPrice}
//                           onChange={(e) => setLimitPrice(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> */}
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group ">
//                         <label>Amount</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={buyLimitAmount}
//                           onChange={(e) => setBuyLimitAmount(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> */}
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
                 
//                   <div className="col-md-12 mt-1">
//                     <div className="buy-d-flex">
//                       <p>
//                         Buy Amount-{" "}
//                         <span>{limitPrice * buyLimitAmount || 0}</span> USDT
//                       </p>
//                       {isLoggedIn && (
//                         <>
//                           <p>
//                             Balance- <span>{userBalance}</span> USDT
//                           </p>
//                         </>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-3">
//                     <div className="button-container">
//                       {!isLoggedIn && (
//                         <>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="sign m-login-btn"
//                             to="/signup"
//                           >
//                             Register
//                           </NavLink>
//                           <span className="or-text">or</span>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="login m-login-btn"
//                             to="/login"
//                           >
//                             Login
//                           </NavLink>
//                         </>
//                       )}
//                     </div>
//                     {/* <button className='btn buy-btn w-100' type='submit'>Buy</button> */}
//                     {isLoggedIn && (
//                       <button
//                         className="btn buy-btn w-100"
//                         disabled={loading}
//                         type="submit"
//                       >
//                        Buy
//                       </button>
//                     )}
//                   </div>
//                 </form>
//               </div>
//               <div className="col-md-6">
//                 <form
//                   className="row"
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleTradeLimit("SELL");
//                   }}
//                 >
//                   <div className="col-md-12">
//                     <div className="main-input-flex">
//                       <div className="form-group">
//                         <label>Limit Price</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={selllimitPrice}
//                           onChange={(e) => setsellLimitPrice(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> */}
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group ">
//                         <label>Amount</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={sellLimitAmount}
//                           onChange={(e) => setSellLimitAmount(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> */}
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="buy-d-flex">
//                       <p>
//                         Sell Amount-{" "}
//                         <span>{selllimitPrice * sellLimitAmount || 0}</span>{" "}
//                         USDT
//                       </p>
//                       {isLoggedIn && (
//                         <>
//                           <p>
//                             Balance- <span>{userBalance}</span> USDT
//                           </p>
//                         </>
//                       )}
//                     </div>
//                   </div>
//                   {/* <div className=' col-md-12 mt-3'>
//                                         <div className='main-input-flex'>
//                                             <div className='form-group '>
//                                                 <label>Total</label>
//                                                 <input type="text" placeholder='0.00' className='form-control mt-1' />
//                                             </div>
//                                             <div className='input-image'>
//                                                 <span className='ms-2'>USD</span>
//                                             </div>
//                                         </div>
//                                     </div> */}
//                   <div className="col-md-12 mt-3">
//                     <div className="button-container">
//                       {!isLoggedIn && (
//                         <>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="sign m-login-btn"
//                             to="/signup"
//                           >
//                             Register
//                           </NavLink>
//                           <span className="or-text">or</span>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="login m-login-btn"
//                             to="/login"
//                           >
//                             Login
//                           </NavLink>
//                         </>
//                       )}
//                     </div>
//                     {isLoggedIn && (
//                       <button
//                         className="btn sell-btn w-100"
//                         disabled={loading}
//                         type="submit"
//                       >
//                         Sell
//                       </button>
//                     )}
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>

//           {/* <div
//             className="tab-pane fade show active"
//             id="profile"
//             role="tabpanel"
//             aria-labelledby="profile-tab"
//           >
//             <div className="tab-trade-content row">
//               <div className="col-md-6">
//                 <form
//                   className="row"
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleTrade("BUY");
//                   }}
//                 >
//                   <div className="col-md-12 ">
//                     <div className="buy-d-flex">
//                       {/* <p>Balance- <span>{userBalancesell}</span> {symbol}</p> 
//                       {isLoggedIn && (
//                         <>
//                           <p>
//                             Balance- <span>{userBalance}</span> USDMDMMDDT
//                           </p>
//                         </>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group">
//                         <label>Price</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={
//                             marketPrice !== null ? marketPrice.toFixed(7) : ""
//                           }
//                           readOnly
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> 
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group ">
//                         <label>Amount</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={buyAmount}
//                           onChange={(e) => setBuyAmount(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={img} alt='buy-sell-image' /> 
//                         <span className="ms-2">{symbol}</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="buy-d-flex">
//                       {/* <p>Buy Amount- <span>{userBalance}</span> USDT</p> 
//                       <p>
//                         Buy Amount- <span>{buyAmount * marketPrice}</span> USDT
//                       </p>
//                       {/* <p>$<span>0.00</span></p> 
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-3">
//                     <div className="button-container">
//                       {!isLoggedIn && (
//                         <>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="sign m-login-btn"
//                             to="/signup"
//                           >
//                             Register
//                           </NavLink>
//                           <span className="or-text">or</span>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="login m-login-btn"
//                             to="/login"
//                           >
//                             Login
//                           </NavLink>
//                         </>
//                       )}
//                     </div>
//                     {/* <button className='btn buy-btn w-100' type='submit'>Buy</button> 
//                     {isLoggedIn && (
//                       <button className="btn buy-btn w-100" type="submit">
//                         Buy
//                       </button>
//                     )}
//                   </div>
//                 </form>
//               </div>
//               <div className="col-md-6">
//                 <form
//                   className="row"
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleTrade("SELL");
//                   }}
//                 >
//                   <div className="col-md-12 ">
//                     <div className="buy-d-flex">
//                       {/* <p>Balance- <span>{userBalancesell}</span> {symbol}</p> 

//                       {isLoggedIn && (
//                         <>
//                           <p>
//                             Balance- <span>{userBalancesell}</span> {symbol}
//                           </p>
//                         </>
//                       )}

//                       {/* <p>Sell Amount- <span>{marketPrice*sellAmount}</span> {symbol}</p>
//                       {/* <p>$<span>0.00</span></p> 
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group">
//                         <label>Price</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={
//                             marketPrice !== null ? marketPrice.toFixed(7) : ""
//                           }
//                           readOnly
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={usd} alt='buy-sell-image' /> 
//                         <span className="ms-2">USDT</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="main-input-flex">
//                       <div className="form-group ">
//                         <label>Amount</label>
//                         <input
//                           type="text"
//                           placeholder="0.00"
//                           className="form-control mt-1"
//                           value={sellAmount}
//                           onChange={(e) => setSellAmount(e.target.value)}
//                         />
//                       </div>
//                       <div className="input-image">
//                         {/* <img src={img} alt='buy-sell-image' /> 
//                         <span className="ms-2">{symbol}</span>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-1">
//                     <div className="buy-d-flex">
//                       {/* <p>Sell Amount- <span>{userBalancesell}</span> {symbol}</p>
//                       <p>
//                         Sell Amount- <span>{marketPrice * sellAmount}</span>{" "}
//                         {symbol}
//                       </p>
//                       {/* <p>$<span>0.00</span></p>
//                     </div>
//                   </div>
//                   <div className="col-md-12 mt-3">
//                     <div className="button-container">
//                       {!isLoggedIn && (
//                         <>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="sign m-login-btn"
//                             to="/signup"
//                           >
//                             Register
//                           </NavLink>
//                           <span className="or-text">or</span>
//                           <NavLink
//                             style={{ color: "#08d1d1" }}
//                             className="login m-login-btn"
//                             to="/login"
//                           >
//                             Login
//                           </NavLink>
//                         </>
//                       )}
//                     </div>
//                     {/* <button className='btn sell-btn w-100' type='submit'>Sell</button>
//                     {isLoggedIn && (
//                       <button className="btn sell-btn w-100" type="submit">
//                         Sell
//                       </button>
//                     )}
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default BuySell;




import React, { useEffect, useState, useContext } from "react";
import { MarketContext } from "../Marketcontextapi";
import usd from "../assets/icons/usd.png";
import { base_url } from "../config/config";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
// import { TradeContext } from './TradeContext';
const BuySell = ({ symbol: tradeSymbol }) => {
  const [marketPrice, setMarketPrice] = useState(null);
  const [limitPrice, setLimitPrice] = useState(null);
  const [selllimitPrice, setsellLimitPrice] = useState(null);
  const [userData, setUserData] = useState(null);
  const { data } = useContext(MarketContext);
  const [buyLimitAmount, setBuyLimitAmount] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [sellLimitAmount, setSellLimitAmount] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [getId, setGetId] = useState("");
  const [img, setImg] = useState("");
  const [symbol, setSymbol] = useState("");
  const [userBalance, setUserBalance] = useState(null);
  const [userBalancesell, setUserBalancesell] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { tradeData } = useContext(TradeContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const filteredData = data.find((item) => item.symbol === tradeSymbol);
        if (filteredData) {
          setGetId(filteredData._id);
          setMarketPrice(filteredData.price);
          setImg(filteredData.image);
          setSymbol(filteredData.symbol);
        } else {
          console.error("Symbol not found in response data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    getUserDetail();
    fetchUserBalance();
    fetchUserBalancesell();
  }, [data, tradeSymbol]);

  // useEffect(() => {
    
  //   if (tradeData.buyPrice) {
  //     setLimitPrice(tradeData.buyPrice);
  //   }
  //   if (tradeData.sellPrice) {
  //     setsellLimitPrice(tradeData.sellPrice);
  //   }
  // }, [tradeData]);
  // console.log('data', data);
  const fetchUserBalance = async () => {
    try {
      const response = await fetch(`${base_url}/useraccountbalance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify({
          tokenName: "USDT",
          wallet_type: "trading_wallet",
        }),
      });

      const responseData = await response.json();
      if (responseData.success) {
        setUserBalance(responseData.balance);
      } else {
        console.error("Failed to fetch user balance:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching user balance:", error);
    }
  };
  const fetchUserBalancesell = async () => {
    try {
      let pastdata = {
        tokenName: tradeSymbol,
        wallet_type: "trading_wallet",
      };
      const response = await fetch(`${base_url}/useraccountbalance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(pastdata),
      });
      // console.log('tokenName', pastdata);

      const responseData = await response.json();
      if (responseData.success) {
        setUserBalancesell(responseData.balance);
      } else {
        console.error("Failed to fetch user balance:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching user balance:", error);
    }
  };

  const handleTrade = async (mode) => {
    const amount =
      mode === "BUY" ? parseFloat(buyAmount) : parseFloat(sellAmount);
    if (isNaN(amount)) {
      console.error(`Invalid ${mode.toLowerCase()} amount`);
      return;
    }
    const data = {
      tokenId: getId,
      mode: mode.toUpperCase(),
      tradingCurrency: "USDT",
      pairCurrency: "662e39e17df77af7d769df5f",
      // pairCurrency: "62c9a988187e31ce104cd317",
      tokenQuantity: amount,
      orderType: "MARKET",
      price: marketPrice,
    };

    try {
      const response = await fetch(`${base_url}/place_trading_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (responseData.success) {
      toast.dismiss();
      toast.success(responseData.message);}
      else {
        toast.dismiss();
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(`${mode} error`);
    }
  };

  const handleTradeLimit = async (mode) => {
    if (mode === "BUY") {
      if (!limitPrice || !buyLimitAmount) {
        toast.dismiss();
        toast.error("Please fill all fields for buy limit");
        return;
      }
    }
    if (mode === "SELL") {
      if (!selllimitPrice || !sellLimitAmount) {
        toast.dismiss();
        toast.error("Please fill all fields for sell limit");
        return;
      }
    }
    setLoading(true);
    const amount =
      mode === "BUY" ? parseFloat(buyLimitAmount) : parseFloat(sellLimitAmount);
    if (isNaN(amount)) {
      console.error(`Invalid ${mode.toLowerCase()} amount`);
      return;
    }
    const data = {
      tokenId: getId,
      mode: mode.toUpperCase(),
      tradingCurrency: "USDT",
      pairCurrency: "662e39e17df77af7d769df5f",
      // pairCurrency: "62c9a988187e31ce104cd317",
      tokenQuantity: amount,
      orderType: "LIMIT",
      limitprice: mode === "BUY" ? limitPrice : selllimitPrice,
    };

    try {
      const response = await fetch(`${base_url}/place_trading_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      if (responseData.success) {
        toast.dismiss();
        toast.success(responseData.message);
        setsellLimitPrice("");
        setBuyLimitAmount("");
        setLimitPrice("");
        setSellLimitAmount("");
        getUserDetail();
      } else {
        toast.dismiss();
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(`${mode} error`);
    } finally {
      setLoading(false);
    }
  };

  const token = localStorage.getItem("token");
  const getUserDetail = async () => {
    const response = await fetch(`${base_url}/v1/userProfile`, {
      method: "get",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    if (data.user) {
      setUserData(data.user);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  

  return (
    <>
      <div className="buy-sell-main trade-column2-h">
        <ul className="nav nav-tabs buysell-tab" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <label
              className="nav-link active buysell-link"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="false"
            >
              <input id="tab1" type="radio" name="tab" className="" />
              Limit
            </label>
          </li>
          
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active "
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="tab-trade-content row">
              <div className="col-md-6">
                <form
                  className="row"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTradeLimit("BUY");
                  }}
                >
                  <div className="col-md-12">
                    <div className="main-input-flex">
                      <div className="form-group">
                        <label>Limit Price</label>
                        <input
                          type="text"
                          placeholder="0.00"
                          className="form-control mt-1"
                          value={limitPrice}
                          onChange={(e) => setLimitPrice(e.target.value)}
                        />
                      </div>
                      <div className="input-image">
                        {/* <img src={usd} alt='buy-sell-image' /> */}
                        <span className="ms-2">USDT</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <div className="main-input-flex">
                      <div className="form-group ">
                        <label>Amount</label>
                        <input
                          type="text"
                          placeholder="0.00"
                          className="form-control mt-1"
                          value={buyLimitAmount}
                          onChange={(e) => setBuyLimitAmount(e.target.value)}
                        />
                      </div>
                      <div className="input-image">
                        {/* <img src={usd} alt='buy-sell-image' /> */}
                        <span className="ms-2">USDT</span>
                      </div>
                    </div>
                  </div>
                 
                  <div className="col-md-12 mt-1">
                    <div className="buy-d-flex">
                      <p>
                        Buy Amount-{" "}
                        <span>{limitPrice * buyLimitAmount || 0}</span> USDT
                      </p>
                      {isLoggedIn && (
                        <>
                          <p>
                            Balance- <span>{userBalance}</span> USDT
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="button-container">
                      {!isLoggedIn && (
                        <>
                          <NavLink
                            style={{ color: "#08d1d1" }}
                            className="sign m-login-btn"
                            to="/signup"
                          >
                            Register
                          </NavLink>
                          <span className="or-text">or</span>
                          <NavLink
                            style={{ color: "#08d1d1" }}
                            className="login m-login-btn"
                            to="/login"
                          >
                            Login
                          </NavLink>
                        </>
                      )}
                    </div>
                    {/* <button className='btn buy-btn w-100' type='submit'>Buy</button> */}
                    {isLoggedIn && (
                      <button
                        className="btn buy-btn w-100"
                        disabled={loading}
                        type="submit"
                      >
                       Buy
                      </button>
                    )}
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <form
                  className="row"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTradeLimit("SELL");
                  }}
                >
                  <div className="col-md-12">
                    <div className="main-input-flex">
                      <div className="form-group">
                        <label>Limit Price</label>
                        <input
                          type="text"
                          placeholder="0.00"
                          className="form-control mt-1"
                          value={selllimitPrice}
                          onChange={(e) => setsellLimitPrice(e.target.value)}
                        />
                      </div>
                      <div className="input-image">
                        {/* <img src={usd} alt='buy-sell-image' /> */}
                        <span className="ms-2">USDT</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <div className="main-input-flex">
                      <div className="form-group ">
                        <label>Amount</label>
                        <input
                          type="text"
                          placeholder="0.00"
                          className="form-control mt-1"
                          value={sellLimitAmount}
                          onChange={(e) => setSellLimitAmount(e.target.value)}
                        />
                      </div>
                      <div className="input-image">
                        {/* <img src={usd} alt='buy-sell-image' /> */}
                        <span className="ms-2">USDT</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-1">
                    <div className="buy-d-flex">
                      <p>
                        Sell Amount-{" "}
                        <span>{selllimitPrice * sellLimitAmount || 0}</span>{" "}
                        USDT
                      </p>
                      {isLoggedIn && (
                        <>
                          <p>
                            Balance- <span>{userBalance}</span> USDT
                          </p>
                        </>
                      )}
                    </div>
                  </div>
              
                  <div className="col-md-12 mt-3">
                    <div className="button-container">
                      {!isLoggedIn && (
                        <>
                          <NavLink
                            style={{ color: "#08d1d1" }}
                            className="sign m-login-btn"
                            to="/signup"
                          >
                            Register
                          </NavLink>
                          <span className="or-text">or</span>
                          <NavLink
                            style={{ color: "#08d1d1" }}
                            className="login m-login-btn"
                            to="/login"
                          >
                            Login
                          </NavLink>
                        </>
                      )}
                    </div>
                    {isLoggedIn && (
                      <button
                        className="btn sell-btn w-100"
                        disabled={loading}
                        type="submit"
                      >
                        Sell
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default BuySell;
