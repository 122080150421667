import React, { useEffect, useState } from 'react'

import c1 from '../../assets/icons/m1-icon.png';

import m1 from '../../assets/icons/m1-icon.png';
import m2 from '../../assets/icons/bnb.png';
import m3 from '../../assets/icons/btc.png';
import m4 from '../../assets/icons/m2-icon.png';
import { MdOutlineSearch } from 'react-icons/md';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import OverHeader from './OverHeader';
import { base_url } from '../../config/config';
import Paginations from '../../Components/Paginations';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import dateFormat from 'dateformat';



const MainAccount = () => {
    // const [page, setPage] = useState(1);
    // const [pageCount, setPageCount] = useState(0);


    const [main, setMain] = useState();
    const [fundData, setFundData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState();
    const [limit, setLImit] = useState(10);
    const [search, setSearch] = useState('');
    const [hideZero, setHideZero] = useState(false);
    // console.log("search", search);


    // console.log('fundData--main', fundData);
    const getData = async () => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/userwallet`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                wallet_type: "main_wallet"
            })
        });
        const data = await response.json();
        if (data.success === 1) {
            // console.log('data--12',data.wallets);
            setMain(data.wallets.main_wallet.balance);
            setFundData(data.wallets.main_wallet.wallets)
            setLoading(false); //
        } else {
            console.error('Error:', response.status);
        }
    };

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [perPage] = useState(10);



    const handleNext = () => {
        setPage(() => {
            if (page === pageCount) return page;
            return page + 1;
        })
    }

    const handlePrevios = () => {
        setPage(() => {
            if (page === 1) return page;
            return page - 1;
        })
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <OverHeader />
            <section>
                <div className='container'>
                    <div className='overview-tab-content'>
                        <div className='over-content-01'>
                            <div className='over-01-left'>
                                <div className='over-pagetitle-01'>
                                    <h2>Main Account History</h2>
                                </div>
                                <div className='d-gap d-flex align-items-center'>
                                    <div className='img-price-01 d-flex align-items-center'>
                                        <img src={c1} alt='coin-image' />
                                        <span>{((main))} USDT</span>
                                    </div>

                                </div>
                            </div>
                            <div className='over-01-right'>
                                <div className=''></div>
                                <div className='over-01-innerdiv'>
                                    {/* <div className='form-group form-group-flex'>
                                        <input id="bls" type='checkbox' onChange={(e) => setHideZero(e.target.checked)} />
                                        <label htmlFor='bls' className='ms-2'>Hide Zero Balances</label>
                                    </div> */}
                                    <div className='form-group'>
                                        <div className='slider-top-srh'>
                                            <input type="search" placeholder='Search Coin Name' onChange={(e) => setSearch(e.target.value)} />
                                            <MdOutlineSearch className='srh-icon' />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='market-table'>

                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th >S No.</th>
                                            <th >Token Name</th>
                                            <th>USD Amount</th>
                                            <th >USD Price</th>
                                            <th>Balance</th>
                                            <th>Date & Time</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fundData
                                            .filter((item) => {
                                                return (search.toUpperCase() === ''
                                                    || item.tokenname.toUpperCase().includes(search.toUpperCase())) && (!hideZero || item.balance > 0);
                                            }).map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td >
                                                            {item ? item.tokenname : ''}

                                                        </td>
                                                        <td>{item ? item.usd_amount : ''}</td>
                                                        <td>{item ? item.usd_price : ''}</td>
                                                        <td>{item ? item.balance : ''}</td>
                                                        <td>{dateFormat(item.createdAt, "dd-mm-yy,hh:mm:ss TT")}</td>
                                                        <td><NavLink to={`/tokenhistory?tokenId=${item.tokenId} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>


                                {/* <div className='market-table-footer'>
                                    <div className='total-page'>
                                        <p>{page}-{pageCount} Of {pageCount}</p>
                                    </div>
                                    <div className='pagination-div'>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <button type="button" className="page-link page-link1" onClick={handlePrevios} disabled={page === 1}><GrFormPrevious /></button>
                                                </li>
                                                <li className="page-item"><span className="page-link page-link1">{page}</span></li>
                                                <li className="page-item">
                                                    <button type="button" className="page-link page-link1" onClick={handleNext} disabled={page === pageCount}><GrFormNext /></button>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default MainAccount



