import React, { useEffect, useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import _fetch from '../config/api';
import { base_url } from '../config/config';
import s1 from '../assets/img/img-signup.png'
import { Modal } from 'react-responsive-modal';
import PopModal from './PopModal';

const Signup = () => {

    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const refId = params.get('refId');
    console.log('refiddd---', refId);

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState({ countryCode: '', phone: '' });
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [agreed, setAgreed] = useState(false);
    const [activeTab, setActiveTab] = useState('email');
    const [countryCode, setCountryCode] = useState('');
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [phoneCountdown, setPhoneCountdown] = useState(0);
    const [sendingEmailOTP, setSendingEmailOTP] = useState(false);
    const [sendingPhoneOTP, setSendingPhoneOTP] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);




    const [isToastDisplayed, setIsToastDisplayed] = useState(false); // Flag to track if a toast message is displayed

    const [isVisible, setIsVisible] = useState(false)
    const [isCpVisible, setIsCpVisible] = useState(false)
    useEffect(() => {
        let timer;
        if (emailCountdown > 0) {
            timer = setTimeout(() => {
                setEmailCountdown(prevCountdown => prevCountdown - 1);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [emailCountdown]);
    console.log('phne-', phone);
    useEffect(() => {
        let timer;
        if (phoneCountdown > 0) {
            timer = setTimeout(() => {
                setPhoneCountdown(prevCountdown => prevCountdown - 1);
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [phoneCountdown]);
    console.log('referralCode', referralCode);

    const sendEmailOTP = async () => {
        if (!emailIsValid(email)) {
            toast.dismiss(); // Dismiss all existing toasts before showing a new one
            toast.error('Invalid Email address');
            return;
        }
        try {

            if (isToastDisplayed) return;
            setSendingEmailOTP(true);
            setIsToastDisplayed(true);


            let postData = {
                "register_type": "email",
                "email": email.toLowerCase(),
                "country_code": countryCode
            }

            const res = await _fetch(`${base_url}/auth/registerOtp`, 'POST', postData, {});
            console.log(res);

            if (res.success === 1) {
                toast.dismiss();
                toast.success(res?.message);
                setEmailCountdown(180);

            } else {
                toast.dismiss();
                toast.error(res?.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        }
        finally {
            setSendingEmailOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const sendPhoneOTP = async () => {
        const isValidPhone = validatePhone(phone);
        if (!isValidPhone) {
            toast.dismiss();
            toast.error('Invalid Phone number');
            return;
        }
        const phoneNumber = phone.phone.substring(phone.countryCode.length);

        // Concatenate the plus sign with the country code
        const formattedPhoneNumber = `+${phone.countryCode}${phoneNumber}`;
        console.log('phoneNumber', phoneNumber);
        try {

            if (isToastDisplayed) return;
            setSendingPhoneOTP(true);
            setIsToastDisplayed(true);




            const Data = {
                register_type: 'phone',
                phone: phoneNumber,
                country_code: `+${phone.countryCode}`,
            };

            const res = await _fetch(`${base_url}/auth/registerOtp`, 'POST', Data, {});
            console.log(res);
            if (res.success === 1) {
                toast.dismiss();
                toast.success(res?.message);

                setPhoneCountdown(180);
            } else {
                toast.dismiss();
                toast.error(res?.message);
            }
        } catch (error) {
            console.error('Error sending Phone OTP:', error);
            toast.error('An error occurred while sending OTP to phone');
        }
        finally {
            setSendingPhoneOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhone = (phone) => {
        const pattern = phonePatterns[phone.countryCode];
        const isValidLength = phone.phone.length >= 7 && phone.phone.length <= 15;
        if (pattern) {
            return isValidLength && pattern.test(phone.phone);
        }
        return isValidLength && /^\+?[1-9]\d{1,14}$/.test(phone.phone);
    };

    const handleSignup = async (signupData) => {
        try {
            const response = await _fetch(`${base_url}/auth/signup`, 'POST', signupData, {});
            console.log('response', response);
            if (response.success === 1) {
                toast.success(response?.message);
                localStorage.setItem("token", response.user.accessToken);
                navigate('/dashboard');

            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while signing up');
        }
    };

    const handleEmailSignup = async () => {
        // console.log('referralCodereferralCode-->',referralCode);

        if (!emailIsValid(email)) {
            toast.dismiss();
            toast.error('Invalid Email address');

            return;
        }

        if (password !== confirmPassword) {
            toast.dismiss();
            toast.error('Passwords do not match');

            return;
        }

        if (!agreed) {
            toast.dismiss();
            toast.error('Please agree to the Terms of Use');

            return;
        }

        if (!verificationCode) {
            toast.dismiss();
            toast.error('Please enter the verification code');

            return;
        }
        try {

            if (isToastDisplayed) return;
            setLoggingIn(true);
            setIsToastDisplayed(true);

            const signupData = {
                email,
                register_type: 'email',
                password,
                otp: verificationCode,
                country_code: countryCode,
                sponser_id: referralCode,
                agreed,
            };

            await handleSignup(signupData);
        } catch (error) {
            console.error('Error signing up via email:', error);
            toast.error('An error occurred while signing up via email');
        }
    };


    const handlePhoneSignup = async () => {
        if (!validatePhone(phone)) {
            toast.dismiss();
            toast.error('Invalid Phone number');
            return;
        }

        if (password !== confirmPassword) {
            toast.dismiss();
            toast.error('Passwords do not match');
            return;
        }

        if (!agreed) {
            toast.dismiss();
            toast.error('Please agree to the Terms of Use');
            return;
        }

        if (!verificationCode) {
            toast.dismiss();
            toast.error('Please enter the verification code');
            return;
        }

        try {
            if (isToastDisplayed) return;
            setLoggingIn(true);
            setIsToastDisplayed(true);

            // Extract phone number without the country code
            const phoneNumber = phone.phone.substring(phone.countryCode.length);

            // Construct the payload with the extracted phone number
            const signupData = {
                phone: phoneNumber,
                register_type: 'phone',
                password,
                otp: verificationCode,
                country_code: `+${phone.countryCode}`,
                sponser_id: referralCode,

                agreed,
            };

            await handleSignup(signupData);
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while signing up');
        }
    };

    const phonePatterns = {
        'US': /^\+1\d{10}$/,
    };

    const [theme, setTheme] = useState(() => {

        const savedTheme = localStorage.getItem('theme');
        return savedTheme || 'light';
    });


    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    useEffect(() => {
        setReferralCode(refId && refId)
    }, [])

    console.log('Setting', onOpenModal);
    return (
        <>
            <div className={`sec1 ${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
                <section className='sec1'>
                    <div className='container-fluid p-0'>
                        <div className='login-row login-row-signup'>
                            <div className='login-column-div1'>
                                <div className='signup-image-div'>
                                    <img src={s1} alt="" />
                                </div>
                            </div>
                            <div className='login-column-div2'>
                                <div className='form-div'>
                                    <div className='login-page-title'>
                                        <h4>Sign Up</h4>
                                    </div>
                                    {/* <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link navlink-1 ${activeTab === 'email' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('email')}
                                                data-bs-toggle="pill"
                                                href="#home"
                                            >
                                                Email
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link navlink-1 ${activeTab === 'phone' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('phone')}
                                                data-bs-toggle="pill"
                                                href="#menu1"
                                            >
                                                Phone
                                            </a>
                                        </li>
                                    </ul> */}


                                    <div className="tab-content">
                                        <div id="home" className={`tab-pane ${activeTab === 'email' ? 'active' : ''} login-form`}>
                                            <form className='mt-3'>

                                                <div className='email-flex-div1 d-flex'>
                                                    <div className='w-100 form-group'>
                                                        <label>Email</label>
                                                        <input type="text" placeholder='Enter Your Email' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control mt-2' />
                                                    </div>
                                                    <div className='otp-btn-div'>

                                                        <button type='button' onClick={sendEmailOTP} disabled={emailCountdown > 0} className='btn otp-btn'>{emailCountdown > 0 ? ` (${emailCountdown}s)` : 'Send OTP'}</button>
                                                    </div>
                                                </div>

                                                <div className='form-group mt-3'>
                                                    <label>Email Verification Code</label>
                                                    <input type="text" placeholder="Enter Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control mt-2" />
                                                </div>

                                                <div className='form-group mt-3 password-div'>
                                                    <label>Password</label>
                                                    <input type={isVisible ? "text" : "password"} placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                    <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                        {
                                                            isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                        }
                                                    </span>

                                                </div>

                                                <div className='form-group mt-3 password-div'>
                                                    <label>Confirm Password</label>
                                                    <input type={isCpVisible ? "text" : "password"} placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control mt-2" />
                                                    <span className='eye-icon-div' onClick={() => setIsCpVisible(!isCpVisible)}>
                                                        {
                                                            isCpVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                        }
                                                    </span>
                                                </div>

                                                <div className='form-group mt-3'>
                                                    <label>Referral Code (Optional)</label>
                                                    <input type="text" placeholder="Enter Code" value={referralCode} onChange={(e) => setReferralCode(e.target.value)} className="form-control mt-2" />
                                                </div>

                                                <div className='form-group mt-3 checkbox-d1'>

                                                    <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} /><label className='ms-2'>I have read & agree to the
                                                        <span> <NavLink style={{ color: "#08d1d1" }} onClick={onOpenModal}>Terms Of Use</NavLink></span>
                                                    </label>
                                                </div>

                                                <div className='form-group mt-4'>
                                                    <button type='button' onClick={handleEmailSignup} className='btn login-btn'>Sign Up</button>
                                                </div>
                                            </form>
                                        </div>

                                        <div id="menu1" className={` tab-pane ${activeTab === 'phone' ? 'active' : ''} login-form`}>
                                            <form className='mt-3'>
                                                <div className='email-flex-div1 d-flex'>
                                                    <div className='w-100 form-group'>
                                                        <label className=''>Phone</label>
                                                        <PhoneInput
                                                            country={'in'}
                                                            value={phone.phone}
                                                            onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
                                                            inputProps={{
                                                                name: 'phone',
                                                                required: true,
                                                                autoFocus: true
                                                            }}
                                                            inputClass={'form-control w-100 mt-2'}
                                                            containerClass={'cccc'}

                                                        />
                                                    </div>
                                                    <div className='otp-btn-div'>
                                                        <button type='button' onClick={sendPhoneOTP} disabled={phoneCountdown > 0} className='btn otp-btn'>{phoneCountdown > 0 ? ` (${phoneCountdown}s)` : 'Send OTP'}</button>


                                                    </div>
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <label>Phone Verification Code</label>
                                                    <input type="text" placeholder="Enter Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control mt-2" />
                                                </div>
                                                <div className='form-group mt-3 password-div'>
                                                    <label>Password</label>
                                                    <input type={isVisible ? "text" : "password"} placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mt-2" />
                                                    <span className='eye-icon-div' onClick={() => setIsVisible(!isVisible)}>
                                                        {
                                                            isVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                        }
                                                    </span>
                                                </div>

                                                <div className='form-group mt-3 password-div'>
                                                    <label>Confirm Password</label>
                                                    <input type={isCpVisible ? "text" : "password"} placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control mt-2" />
                                                    <span className='eye-icon-div' onClick={() => setIsCpVisible(!isCpVisible)}>
                                                        {
                                                            isCpVisible ? <FaRegEye className='eye-icon' /> : <FaRegEyeSlash className='eye-icon' />
                                                        }
                                                    </span>
                                                </div>

                                                <div className='form-group mt-3'>
                                                    <label>Referral Code (Optional)</label>
                                                    <input type="text" placeholder="Enter Code" value={referralCode} onChange={(e) => setReferralCode(e.target.value)} className="form-control mt-2" />
                                                </div>

                                                <div className='form-group mt-3 checkbox-d1'>
                                                    <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} /><label className='ms-2'  >I have read & agree to the <span> <NavLink onClick={onOpenModal}>Terms Of Use</NavLink></span></label>
                                                </div>

                                                <div className='form-group mt-4'>
                                                    <button type='button' onClick={handlePhoneSignup} className='btn login-btn'>Sign Up</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='form-footer mt-3 text-center'>
                                        <p>Have an account? <NavLink to="/login">Log In here</NavLink> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <PopModal
                open={open}
                onClose={onCloseModal}
                onCloseModal={onCloseModal}
            />
        </>
    );
};

export default Signup;
