import React, { useEffect, useContext, useState } from 'react'
import ProfileSidebar from './ProfileSidebar'
import ProfileTopHeader from './ProfileTopHeader'

import toast, { clearAll } from 'react-hot-toast';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { FaCircle, FaRegEye, FaRegEyeSlash } from "react-icons/fa";

import _fetch from '../../config/api';
import { base_url } from '../../config/config';

import { ProfileContext } from '../../Profilecontextapi';

const UserVerify = () => {
    const navigate = useNavigate();
    const { userData, loading, getUserDetail } = useContext(ProfileContext);
    useEffect(() => {
        getUserDetail();
    }, []);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [phone, setPhone] = useState({ countryCode: '', phone: '' });
    ;
    const [emailCountdown, setEmailCountdown] = useState(0);
    const [phoneCountdown, setPhoneCountdown] = useState(0);
    const [sendingPhoneOTP, setSendingPhoneOTP] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [sendingEmailOTP, setSendingEmailOTP] = useState(false);
    const [isToastDisplayed, setIsToastDisplayed] = useState(false);
    const token = localStorage.getItem("token");


    useEffect(() => {
        let timer;
        if (emailCountdown > 0) {
            timer = setTimeout(() => {
                setEmailCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [emailCountdown]);
    // console.log('phne-', phone);
    useEffect(() => {
        let timer;
        if (phoneCountdown > 0) {
            timer = setTimeout(() => {
                setPhoneCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [phoneCountdown]);

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePhone = (phoneNumber) => {
        const pattern = phonePatterns[phone.countryCode];
        if (pattern) {
            return pattern.test(phoneNumber);
        }
        return /^\+?[1-9]\d{1,14}$/.test(phoneNumber);
    };

    const phonePatterns = {
        'US': /^\+1\d{10}$/,
    };

    const sendEmailOTP = async () => {
        if (!emailIsValid(email)) {
            toast.dismiss();
            toast.error('Invalid Email address');
            return;
        }

        try {
            const response = await fetch(`${base_url}/auth/verificationotp`, {
                method: 'Post',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_type: 'email',
                    email: email
                }),
            });
            const res = await response.json();
            if (res.success === 1) {
                toast.dismiss();
                toast.success(res.message);
                setEmailCountdown(60);

            } else {
                toast.dismiss();
                toast.error(res.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        }
        finally {
            setSendingEmailOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };
    const handleEmail = async () => {
        try {
            const response = await fetch(`${base_url}/auth/verfication`, {
                method: 'Post',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_type: 'email',
                    email: email,
                    otp: verificationCode
                }),
            });
            const res = await response.json();
            // console.log('res--verfication', res);
            if (res.success === 1) {
                toast.dismiss();
                toast.success(res.message);
                navigate('/userdetail');

            } else {
                toast.dismiss();
                toast.error(res.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        }
    }


    const sendPhoneOTP = async () => {
        const phoneNumber = phone.phone.substring(phone.countryCode.length);
        const formattedPhoneNumber = `+${phone.countryCode}${phoneNumber}`;

        if (!validatePhone(phoneNumber)) {
            toast.dismiss(); // Dismiss all existing toasts before showing a new one
            toast.error('Invalid Phone Number');
            return;
        }

        try {
            const response = await fetch(`${base_url}/auth/verificationotp`, {
                method: 'Post',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_type: 'phone',
                    phone: phoneNumber,
                    country_code: `+${phone.countryCode}`,
                }),
            });
            const res = await response.json();
            if (res.success === 1) {
                toast.dismiss();
                toast.success(res.message);
                setPhoneCountdown(60);

            } else {
                toast.dismiss();
                toast.error(res.message);
            }
        } catch (error) {
            console.error('Error sending phone OTP:', error);
            toast.error('An error occurred while sending OTP to phone');
        }
        finally {
            setSendingPhoneOTP(false);
            setTimeout(() => {
                setIsToastDisplayed(false);
            }, 1000);
        }
    };

    const handlePhone = async () => {


        // const phoneNumber = phone.phone.substring(phone.countryCode.length);
        const phoneNumber = phone.phone.substring(phone.countryCode.length);
        try {
            const response = await fetch(`${base_url}/auth/verfication`, {
                method: 'Post',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_type: 'phone',
                    phone: phoneNumber,
                    country_code: `+${phone.countryCode}`,
                    otp: verificationCode
                }),
            });
            const res = await response.json();
            console.log('res--verfication', res);
            if (res.success === 1) {
                toast.dismiss();
                toast.success(res.message);
                navigate('/dashboard');

            } else {
                toast.dismiss();
                toast.error(res.message);
            }
        } catch (error) {
            console.error('Error sending email OTP:', error);
            toast.dismiss();
            toast.error('An error occurred while sending OTP to email');
        }
    }

    return (
        <>

            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div '>
                                <ProfileTopHeader />
                                {(userData?.email_verified !== true || userData?.phone_verified !== true) ?
                                    (<div className='show-popup'>
                                        <h4>  <FaCircle className="text-danger-glow blink" />Please verify Your Account  With Your {'Phone'}</h4>
                                        <NavLink to="/user-verify" className=" btn-1 login-btn1">Verify Your {'Phone'} </NavLink>

                                    </div>) :
                                    ''
                                }
                                <div className='ref-page-title'>
                                    {/* <h3>Please Verify With Your{userData?.email_verified ? ' Phone Number' : ' Email'}</h3> */}
                                </div>
                                <div className='card userverify-card'>

                                    {!userData?.phone_verified &&
                                        <>
                                            <form className='mt-3'>
                                                <div className='email-flex-div1 d-flex'>
                                                    <div className='form-group w-100'>
                                                        <label>Phone</label>
                                                        <PhoneInput
                                                            country={'in'}
                                                            value={phone.phone}
                                                            onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
                                                            inputProps={{
                                                                name: phone,
                                                                required: true,
                                                                autoFocus: true
                                                            }}
                                                            inputClass={'form-control w-100 mt-2'}
                                                        />
                                                    </div>
                                                    <div className='otp-btn-div'>
                                                        <button type='button' onClick={sendPhoneOTP} disabled={phoneCountdown > 0 || sendingPhoneOTP} className='btn otp-btn'>{sendingPhoneOTP ? 'Sending...' : (phoneCountdown > 0 ? `(${phoneCountdown}s)` : 'Send OTP')}</button>
                                                    </div>
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <label>Verification Code</label>
                                                    <input type="text" placeholder="Enter Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} className="form-control mt-2" />
                                                </div>


                                                <div className='form-group mt-4 t-center'>
                                                    <button type='button' onClick={handlePhone} disabled={loggingIn} className='btn login-btn '>{loggingIn ? 'Verify' : 'Submit'}</button>
                                                </div>
                                            </form>
                                        </>

                                    }




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default UserVerify