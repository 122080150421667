import React from 'react'
import { useState, useEffect } from 'react'
import faqi from '../../assets/icons/faq-1.png'
import setup from '../../assets/img/set-up.png'
import phone from '../../assets/img/phone-call.png'
import mail from '../../assets/icons/icon-1.png'
import mail1 from '../../assets/icons/icon-2.png'
import mail2 from '../../assets/icons/icon-3.png'
import bubble from '../../assets/img/bubble-chat.png'
import { base_url } from '../../config/config'
import { NavLink } from 'react-router-dom'
import faqMid from '../../assets/img/sup-email.png'

const Faq = () => {
    // const [faqData, setFaqData] = useState(null);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`${base_url}/faq`);
    //             const data = await response.json();
    //             setFaqData(data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);
    return (
        <>
            <section className="faq01 faq-banner">
                <div className="container">
                    <div className="row banner">
                        <div className="col-lg-6 col-md-6 align-self">
                            <div className="col_1">
                                <h4 class="sb market_sub_title outfit">Quick Answers</h4>
                                <h1 className="w banner_heading outfit">FAQ</h1>
                                <p className="banner_text w outfit">Feeling lost in the crypto maze? Don't worry! Dive into our FAQ section and let us guide you through the world of Bitnetto step by step. Get ready for an adventure as we unravel the mysteries of crypto together! </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-center py-3">
                            <img className='banner_img_faq' src={faqi}></img>
                        </div>
                    </div>
                </div>
            </section>

            <section className='faq-iconbox'>
                <div className='container'>
                    <h4 className="sb text-center market_sub_title outfit">Benefits</h4>
                    <h2 className="w text-center market_title outfit">Exciting Features Await</h2>
                    <p className="banner_text w outfit text-center gr">Don't settle for the ordinary; elevate your crypto journey gradually. You'll soon be singing our praises!</p>
                    <div className="margin_top ">
                        <div className="row mt-5 faq-row-flex">



                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="https://www.facebook.com/bitnetto.io">
                                                <img src={mail}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="https://www.facebook.com/bitnetto.io">www.facebook.com/bitnetto.io</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">www.facebook.com/bitnetto.io</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Facebook</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Facebook us for a swift, laser-focus support.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="https://www.youtube.com/@bitnetto">
                                                <img src={mail1}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="https://www.youtube.com/@bitnetto">www.youtube.com/@bitnetto</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">www.youtube.com/@bitnetto</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Youtube</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Youtube us for a swift, laser-focus support.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="icon_box-jaw icon_box-jaw1 text-center ">
                                    <div className="jaw_icon_box_img">
                                        <div className='jaw_icon_box_img_box'>
                                            <a href="mailto:support@bitnetto.io">
                                                <img src={mail2}></img>
                                            </a>
                                        </div>
                                    </div>
                                    <a style={{ color: "#08d1d1" }} href="mailto:support@bitnetto.io">support@bitnetto.io</a>
                                    {/* <h5 className="profile_box_title text-center outfit w">support@bitnetto.io</h5> */}
                                    <h5 className="profile_box_title text-center outfit w">Email</h5>
                                    <p className="banner_text gr outfit text-center mb-0">Email us for a swift, laser-focus support.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='faq-cc'>
                <div className='container'>
                    <nav>
                        <div class="nav nav-tabs faq_nav_tab" id="nav-tab" role="tablist">
                            <button class="nav-link outfit active faq_tab_btn" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Bitnetto</button>
                            <button class="nav-link outfit faq_tab_btn" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Cryptocurrency</button>
                            {/* <button class="nav-link outfit faq_tab_btn" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Launchpad</button> */}
                            <button class="nav-link outfit faq_tab_btn" id="nav-disabled-tab" data-bs-toggle="tab" data-bs-target="#nav-disabled" type="button" role="tab" aria-controls="nav-disabled" aria-selected="false">Buy Crypto</button>
                        </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                        <div className='margin-top'></div>
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Want to Join Bitnetto?

                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Excellent choice! Here's how to get started: Visit our website and simply click the 'Register' button. You'll be directed to a registration page where you can fill in your details. Remember to complete all required fields and click 'Register Now' once you're done. To finish the KYC (Know Your Customer) process and registration, make sure to have your proof of identity and address handy. Access the KYC verification page by either clicking 'Verify now' in the login pop-up or navigating to your profile in the top right corner, then select 'My Account' followed by 'KYC Verification'. Click 'Verify your KYC' to begin. It's as easy as that!"</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Ready to Add Funds?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Once you've signed up with Bitnetto, you'll receive three wallets, Now, let's get those wallets ready for your crypto investments. To do this, head to the Transactions tab and select the deposit option. A prompt box will appear where you can choose the coins    (USDT(BEP 20), USDT( TRC 20), RBC, GLC) and network, then select the address. A QR code will pop up, which you can copy or scan using MetaMask, Coinbase , Trust Wallet or any other wallet you prefer. Simply copy the code into your wallet, and you're all set to make your transaction. Happy investing!</div>
                                            </div>
                                        </div>
                                        {/* <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingThree">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Ready to buy some crypto? Let us show you how it's done.
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">We offer you two ways to buy crypto. First, if you have already deposited fiat currency into your wallet, you can easily convert that currency to the desired cryptocurrency. Select the currency you wish to convert from and the cryptocurrency you want to buy and confirm the transaction.
                                                    Alternatively, you can buy cryptocurrency directly using your credit/debit card. Please note that there may be limits on the amount you can buy, depending on your payment method and country of residence.</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne1">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                    Ready to jump into the world of crypto? Want to see how it's done?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">
                                                    Ready to step into the world of crypto? You've got two paths: convert your current currency into your desired crypto, or purchase directly. Just remember, there could be limits depending on your payment method and location. Ready to begin?</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo2">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
                                                    Looking to withdraw? Don't worry, we've got you covered! Here's a quick guide on how to do it.
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Withdrawing your assets from Bitnetto couldn't be simpler! Just head to the "Wallet" tab, hit "Withdraw", and choose the asset you want to withdraw. Fill in the necessary details, go through our 2FA security process, and you're good to go! Once you've submitted, you'll get a notification confirming your successful withdrawal. And remember, at Bitnetto, your security is our priority, so you can trust that your assets are in safe hands.</div>
                                            </div>
                                        </div>
                                        {/* <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingThree3">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
                                                    The big question: Are there any fees? Let's break it down.
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree3" class="accordion-collapse collapse" aria-labelledby="flush-headingThree3" data-bs-parent="#accordionFlushExample3">
                                                <div class="accordion-body outfit">At Bitnetto, we believe in giving you control over your investment, so we're transparent about our fees. Our fees are listed below:
                                                    -- TABLE HERE --</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Feeling overwhelmed by the crypto world as a newcomer?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Worry not! At Bitnetto, we're here to support you every step of the way. Our platform is built with user-friendliness in mind, making it easy for newcomers to dive into the world of crypto investing. Plus, if you ever feel lost, we've got detailed guides to get you up to speed in no time. Ready to embark on this exciting journey with us? Join Bitnetto today, and let's explore the fascinating world of crypto together!</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne1">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                    Is trading crypto risky? Want to discuss?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">While trading crypto carries inherent risks, we're committed to prioritizing the safety and security of our users' funds at Bitnetto. Rest assured, we adhere to industry-standard security protocols and regulatory requirements to mitigate these risks. So, even though trading involves some level of uncertainty, you can have confidence that your assets are in good hands with us.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    How do I buy Bitnetto tokens?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">You can buy Bitnetto tokens on various cryptocurrency exchanges or directly from the Bitnetto website. Once you have Bitnetto in your digital wallet, you can use them to contribute to the crowdfunding campaign.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Is Bitnetto safe to use for contributions?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Absolutely! Bitnetto uses state-of-the-art encryption and blockchain technology to ensure all transactions are secure and transparent. Plus, no hidden fees or intermediaries are involved, so you can be sure that your contributions are going directly to the project.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingThree">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    What happens if the crowdfunding campaign doesn't reach its goal?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">If the crowdfunding campaign doesn't reach its goal, all contributions made using Bitnetto tokens will be returned to the contributors. This ensures that your contributions are only used if the project is successful.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne1">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                    Can I use other cryptocurrencies to contribute to the crowdfunding campaign?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">No, the crowdfunding campaign only accepts contributions in Bitnetto tokens. However, you can easily buy Bitnetto on various cryptocurrency exchanges or directly from the Bitnetto website.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo2">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
                                                    What happens to the Bitnetto tokens after the crowdfunding campaign?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">The Bitnetto tokens collected during the crowdfunding campaign will be used to fund the project. If any Bitnetto tokens remain, they will be held by the project team for future use.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingThree3">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree3" aria-expanded="false" aria-controls="flush-collapseThree3">
                                                    Is there a limit to how much I can contribute using Bitnetto tokens?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree3" class="accordion-collapse collapse" aria-labelledby="flush-headingThree3" data-bs-parent="#accordionFlushExample3">
                                                <div class="accordion-body outfit">No limit exists to how much you can contribute using Bitnetto tokens. However, ensuring you have enough Bitnetto in your digital wallet is vital to cover your contribution.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div class="tab-pane fade" id="nav-disabled" role="tabpanel" aria-labelledby="nav-disabled-tab" tabindex="0">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Ready to start trading on our platform?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Getting started is simple – just sign up and follow our user-friendly onboarding process</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Curious about the cryptocurrencies available for trading on our platform?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Our exchange offers support for a variety of cryptocurrencies, such as Bitcoin, Ethereum, Litecoin, and numerous others</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div class="accordion accordion-flush" id="accordionFlushExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingOne1">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne1" aria-expanded="false" aria-controls="flush-collapseOne1">
                                                    What's the usual timeframe for processing deposits and withdrawals?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne1" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Deposits and withdrawals usually happen pretty quickly, usually within a few minutes. However, it can vary depending on how you're paying and how busy the network is.</div>
                                            </div>
                                        </div>
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="flush-headingTwo2">
                                                <button class="accordion-button collapsed outfit" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo2" aria-expanded="false" aria-controls="flush-collapseTwo2">
                                                    How do you ensure the safety of my funds and personal information?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo2" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body outfit">Your security is our priority, and we've got robust measures in place to safeguard your funds and personal data. Think two-factor authentication, SSL encryption, and even cold storage for user funds.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq
