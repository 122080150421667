

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { io } from "socket.io-client";
import { socket_url } from "../config/config";

const CandlestickChart = ({ symbol }) => {
  const [chartData, setChartData] = useState([]);
  const [lastData, setLastData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (symbol !== "GLC" ) {
      setChartData([]);
      setIsLoading(false);
      return;
    }

    const socket = io(socket_url, { transports: ["websocket"] });
    socket.on("connect", () => {
      console.log("Connected to socket");
  
      // Send the subscribeToken to the server
      socket.emit("subscribeToken",  symbol );
  
      console.log("Subscribed to:", symbol);
    });
    

    socket.on("data", (data) => {
      if (!data || data?.length === 0) {
        setChartData([]);
        setIsLoading(false);
        return;
      }

      console.log("Received Data:", data);

      const sortedData = data?.filter(item => item.open !== undefined && item.high !== undefined && item.low !== undefined && item.close !== undefined)
        ?.sort((a, b) => new Date(a.time) - new Date(b.time));

      const formattedData = sortedData.map((item) => ({
        x: new Date(item.time),
        y: [item.open, item.high, item.low, item.close],
        // subscribeToken: symbol,
      }));

      console.log("Formatted Data:", formattedData);

      if (!lastData || formattedData.length !== lastData.length || 
          formattedData[formattedData.length - 1].x.getTime() !== lastData[lastData.length - 1].x.getTime()) {
        setChartData(formattedData);
        setLastData(formattedData);
      }

      setIsLoading(false);
    });

    return () => {
      socket.disconnect();
    };
  }, [symbol, lastData]);

  const options = {
    chart: {
      type: "candlestick",
      height: 350,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : chartData.length === 0 ? (
        <p style={{ textAlign: "center", fontSize: "18px", color: "white" }}>Chart Data Not Found</p>
      ) : (
        <ReactApexChart 
          key={chartData.length} 
          options={options} 
          series={[{ name: "Candlestick", data: chartData }]} 
          type="candlestick" 
          height={350} 
        />
      )}
    </div>
  );
};

export default CandlestickChart;