
// import React, { useState } from 'react';
// import { FaArrowsRotate } from 'react-icons/fa6';
// import id1 from '../../assets/img/id-1.png';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import KycTab from './KycTab';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
// import { ColorRing } from 'react-loader-spinner';
// const UserPan = () => {
//     const [panNumber, setPanNumber] = useState('');
//     const [panImage, setPanImage] = useState('');
//     const [panImageUrl, setPanImageUrl] = useState('');
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(true);

//     const handleSubmit = async () => {
//         if (!panNumber || !panImage) {
//             toast.dismiss();
//             toast.error('Please fill all the required fields.');
//             return;
//         }

//         const token = localStorage.getItem("token");

//         try {
//             // Upload PAN details
//             const panDetailsResponse = await fetch(`${base_url}/pan_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     pan_number: panNumber
//                 })
//             });
//             const panDetailsData = await panDetailsResponse.json();

//             if (panDetailsData.success !== 1) {
//                 toast.dismiss();
//                 toast.error(panDetailsData.message);
//                 return;
//             }
//             toast.dismiss();
//             toast.success(panDetailsData.message);

//             // Upload PAN image
//             const formData = new FormData();
//             formData.append('pan_image', panImage);

//             const panImageResponse = await fetch(`${base_url}/upload_pan_image`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });
//             const panImageData = await panImageResponse.json();

//             if (panImageData.success !== 1) {
//                 // toast.dismiss();
//                 // toast.error(panImageData.message);
//                 return;
//             }
//             // toast.dismiss();
//             // toast.success(panImageData.message);

//             console.log('Pan details and PAN image uploaded successfully');
//             navigate('/UserKyc');
//         } catch (error) {
//             console.error('Error:', error);
//             // toast.dismiss();
//             // toast.error('Error occurred while submitting data');
//         }
//     };

//     const handlePanImageChange = (e) => {
//         const file = e.target.files[0];
//         // Check if a file was selected
//         if (file) {
//             // Check if the selected file is an image
//             if (file.type && file.type.startsWith('image')) {

//                 // Set the selected image and its URL
//                 setPanImage(file);
//                 setPanImageUrl(URL.createObjectURL(file));
//             } else {
//                 // Display an error message or handle the non-image file selection
//                 toast.dismiss();
//                 toast.error('Please select an image file.');
//             }
//         }
//     };

//     return (
//         <>

//             <section className='kyc-profile-main'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                             <ProfileTopHeader />
//                                 <div className='kyc-01'>
//                                     <div className='container'>
//                                         <div className='kyc-row'>
//                                             <div className='kyc-01-heading'>
//                                                 <span className="number">02</span>
//                                                 <div className=''>
//                                                     <h5>PAN Details Upload</h5>
//                                                     To Verify Your PAN, Please Upload Your PAN Details
//                                                 </div>
//                                             </div>
//                                             <div className='kyc-tab-main'>
//                                                 <div className='tab-form-main'>
//                                                     <form>
//                                                         <div className='form-inner-heading mt-5'>
//                                                             <span><FaArrowsRotate className='drop-ic' /></span>
//                                                             <h4>Fill PAN Detail</h4>
//                                                         </div>
//                                                         <div className='row'>
//                                                             <div className='form-group col-md-12 mt-3'>
//                                                                 <label>Enter PAN No</label>
//                                                                 <input
//                                                                     type="text"
//                                                                     placeholder=' i.e.   ABCDE1234F'
//                                                                     className='form-control mt-2'
//                                                                     value={panNumber}
//                                                                     onChange={e => setPanNumber(e.target.value)}
//                                                                 />
//                                                             </div>
//                                                             <div className='form-group col-md-12 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="panImage">
//                                                                     <img src={panImageUrl || id1} alt="PAN" />
//                                                                     <input
//                                                                         type="file"
//                                                                         id="panImage"
//                                                                         style={{ display: "none" }}
//                                                                         onChange={handlePanImageChange}
//                                                                     />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-2" htmlFor="panImage">Select PAN Image</label>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className="form-group col-md-12 text-center mt-5">
//                                                             <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                         </div>
//                                                     </form>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default UserPan;

// import React, { useState } from 'react';
// import { FaArrowsRotate } from 'react-icons/fa6';
// import id1 from '../../assets/img/id-1.png';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import KycTab from './KycTab';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
// import { ColorRing } from 'react-loader-spinner';
// // import './UserPan.css'; // Import your CSS file

// const UserPan = () => {
//     const [panNumber, setPanNumber] = useState('');
//     const [panImage, setPanImage] = useState('');
//     const [panImageUrl, setPanImageUrl] = useState('');
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false); // Initially set loading to false

//     const handleSubmit = async () => {
//         if (!panNumber || !panImage) {
//             toast.dismiss();
//             toast.error('Please fill all the required fields.');
//             return;
//         }

//         setLoading(true); // Set loading to true when submitting

//         const token = localStorage.getItem("token");

//         try {
//             // Upload PAN details
//             const panDetailsResponse = await fetch(`${base_url}/pan_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     pan_number: panNumber
//                 })
//             });
//             const panDetailsData = await panDetailsResponse.json();

//             if (panDetailsData.success !== 1) {
//                 toast.dismiss();
//                 toast.error(panDetailsData.message);
//                 setLoading(false); // Reset loading state
//                 return;
//             }
//             toast.dismiss();
//             toast.success(panDetailsData.message);

//             // Upload PAN image
//             const formData = new FormData();
//             formData.append('pan_image', panImage);

//             const panImageResponse = await fetch(`${base_url}/upload_pan_image`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });
//             const panImageData = await panImageResponse.json();

//             if (panImageData.success !== 1) {
//                 setLoading(false); // Reset loading state
//                 return;
//             }

//             console.log('Pan details and PAN image uploaded successfully');
//             navigate('/UserKyc');
//         } catch (error) {
//             console.error('Error:', error);
//             setLoading(false); // Reset loading state
//         }
//     };

//     const handlePanImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             if (file.type && file.type.startsWith('image')) {
//                 setPanImage(file);
//                 setPanImageUrl(URL.createObjectURL(file));
//             } else {
//                 toast.dismiss();
//                 toast.error('Please select an image file.');
//             }
//         }
//     };

//     return (
//         <>
//             <section className={`kyc-profile-main ${loading ? 'overlay' : ''}`}>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                                 <ProfileTopHeader />
//                                 <div className='kyc-01'>
//                                     <div className='container'>
//                                         <div className='kyc-row'>
//                                             <div className='kyc-01-heading'>
//                                                 <span className="number">02</span>
//                                                 <div className=''>
//                                                     <h5>PAN Details Upload</h5>
//                                                     To Verify Your PAN, Please Upload Your PAN Details
//                                                 </div>
//                                             </div>
//                                             <div className='kyc-tab-main'>
//                                                 <div className='tab-form-main'>
//                                                     <form>
//                                                         <div className='form-inner-heading mt-5'>
//                                                             <span><FaArrowsRotate className='drop-ic' /></span>
//                                                             <h4>Fill PAN Detail</h4>
//                                                         </div>
//                                                         <div className='row'>
//                                                             <div className='form-group col-md-12 mt-3'>
//                                                                 <label>Enter PAN No</label>
//                                                                 <input
//                                                                     type="text"
//                                                                     placeholder=' i.e.   ABCDE1234F'
//                                                                     className='form-control mt-2'
//                                                                     value={panNumber}
//                                                                     onChange={e => setPanNumber(e.target.value)}
//                                                                 />
//                                                             </div>
//                                                             <div className='form-group col-md-12 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="panImage">
//                                                                     <img src={panImageUrl || id1} alt="PAN" />
//                                                                     <input
//                                                                         type="file"
//                                                                         id="panImage"
//                                                                         style={{ display: "none" }}
//                                                                         onChange={handlePanImageChange}
//                                                                     />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-2" htmlFor="panImage">Select PAN Image</label>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className="form-group col-md-12 text-center mt-5">
//                                                             {/* {loading ? (
//                                                                 <ColorRing color="#00BFFF" height={50} width={50} />
//                                                             ) : (
//                                                                 <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                             )} */}
//                                                             {loading ? (
//                                                                 <ColorRing color="#00BFFF" height={50} width={50} />
//                                                             ) : (
//                                                                 <>
//                                                                     <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                                     {uploadingImage && <p>Please wait, image is uploading...</p>}
//                                                                 </>
//                                                             )}
//                                                         </div>
//                                                     </form>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default UserPan;

// import React, { useState } from 'react';
// import { FaArrowsRotate } from 'react-icons/fa6';
// import id1 from '../../assets/img/id-1.png';
// import { useNavigate } from 'react-router-dom';
// import { base_url } from '../../config/config';
// import toast from 'react-hot-toast';
// import KycTab from './KycTab';
// import ProfileSidebar from '../ProfilePages/ProfileSidebar';
// import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
// import { ColorRing } from 'react-loader-spinner';
// // import './UserPan.css'; // Import your CSS file

// const UserPan = () => {
//     const [panNumber, setPanNumber] = useState('');
//     const [panImage, setPanImage] = useState('');
//     const [panImageUrl, setPanImageUrl] = useState('');
//     const navigate = useNavigate();
//     const [loading, setLoading] = useState(false); 
//     const [uploadingImage, setUploadingImage] = useState(false); 

//     const handleSubmit = async () => {
//         if (!panNumber || !panImage) {
//             toast.dismiss();
//             toast.error('Please fill all the required fields.');
//             return;
//         }
//         setLoading(true); 
//         const token = localStorage.getItem("token");
//         try {
//             const panDetailsResponse = await fetch(`${base_url}/pan_details`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     pan_number: panNumber
//                 })
//             });





//             const panDetailsData = await panDetailsResponse.json();
//             if (panDetailsData.success !== 1) {
//                 toast.dismiss();
//                 toast.error(panDetailsData.message);
//                 setLoading(false); 
//                 return;
//             }

//             toast.dismiss();
//             toast.success(panDetailsData.message); 



//             const formData = new FormData();
//             formData.append('pan_image', panImage);
//             setUploadingImage(true);
//             const panImageResponse = await fetch(`${base_url}/upload_pan_image`, {
//                 method: 'POST',
//                 headers: {
//                     Authorization: token,
//                 },
//                 body: formData
//             });
//             const panImageData = await panImageResponse.json();
//             if (panImageData.success !== 1) {
//                 setLoading(false); 
//                 setUploadingImage(false); 
//                 toast.error(panImageData.message);
//                 return;
//             }

//             console.log('Pan details and PAN image uploaded successfully',panImageData);
//             // navigate('/UserKyc');
//             try {

//                 navigate('/UserKyc');
//             } catch (error) {
//                 console.error('Error navigating:', error);
//             } finally {
//                 // Reset loading and uploadingImage states after navigation
//                 setLoading(false);
//                 setUploadingImage(false);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setLoading(false); // Reset loading state
//             setUploadingImage(false); // Reset uploadingImage state
//         }
//     };


//     const handlePanImageChange = (e) => {
//         const file = e.target.files[0];
//         if (file) {
//             if (file.type && file.type.startsWith('image')) {
//                 setPanImage(file);
//                 setPanImageUrl(URL.createObjectURL(file));
//             } else {
//                 toast.dismiss();
//                 toast.error('Please select an image file.');
//             }
//         }
//     };


//     return (
//         <>
//             <section className={`kyc-profile-main ${loading || uploadingImage ? 'overlay' : ''}`}>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-md-3 col-xl-dnone'>
//                             <div className='profile-sidebar-main'>
//                                 <ProfileSidebar />
//                             </div>
//                         </div>
//                         <div className='col-md-9'>
//                             <div className='profile-main-div'>
//                                 <ProfileTopHeader />
//                                 <div className='kyc-01'>
//                                     <div className='container'>
//                                         <div className='kyc-row'>
//                                             <div className='kyc-01-heading'>
//                                                 <span className="number">02</span>
//                                                 <div className=''>
//                                                     <h5>PAN Details Upload</h5>
//                                                     <p>To Verify Your PAN, Please Upload Your PAN Details</p>
//                                                 </div>
//                                             </div>
//                                             <div className='kyc-tab-main'>
//                                                 <div className='tab-form-main'>
//                                                     <form>
//                                                         <div className='form-inner-heading mt-5'>
//                                                             <span><FaArrowsRotate className='drop-ic' /></span>
//                                                             <h4>Fill PAN Detail</h4>
//                                                         </div>
//                                                         <div className='row'>
//                                                             <div className='form-group col-md-12 mt-3'>
//                                                                 <label>Enter PAN No</label>
//                                                                 <input
//                                                                     type="text"
//                                                                     placeholder=' i.e.   ABCDE1234F'
//                                                                     className='form-control mt-2'
//                                                                     value={panNumber}
//                                                                     onChange={e => setPanNumber(e.target.value)}
//                                                                 />
//                                                             </div>
//                                                             <div className='form-group col-md-12 mt-5 profile-image text-center'>
//                                                                 <label htmlFor="panImage">
//                                                                     <img src={panImageUrl || id1} alt="PAN" />
//                                                                     <input
//                                                                         type="file"
//                                                                         id="panImage"
//                                                                         style={{ display: "none" }}
//                                                                         onChange={handlePanImageChange}
//                                                                     />
//                                                                 </label>
//                                                                 <div className="text-center">
//                                                                     <label className="btn_upload mt-2" htmlFor="panImage">Select PAN Image</label>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <div className="form-group col-md-12 text-center mt-5">
//                                                             {loading ? (
//                                                                 <ColorRing color="#00BFFF" height={50} width={50} />
//                                                             ) : (
//                                                                 <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>
//                                                             )}
//                                                             {uploadingImage && loading && (
//                                                                 <p>Please wait, image is uploading...</p>
//                                                             )}
//                                                         </div>

//                                                     </form>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default UserPan;
















import React, { useState } from 'react';
import { FaArrowsRotate } from 'react-icons/fa6';
import id1 from '../../assets/img/id-1.png';
import { useNavigate } from 'react-router-dom';
import { base_url } from '../../config/config';
import toast from 'react-hot-toast';
import KycTab from './KycTab';
import ProfileSidebar from '../ProfilePages/ProfileSidebar';
import ProfileTopHeader from '../ProfilePages/ProfileTopHeader';
import { ColorRing } from 'react-loader-spinner';
import PuffLoader from 'react-spinners/PuffLoader';


const UserPan = () => {
    const [panNumber, setPanNumber] = useState('');
    const [panImage, setPanImage] = useState('');
    const [panImageUrl, setPanImageUrl] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const handleSubmit = async () => {
        if (!panNumber || !panImage) {
            toast.dismiss();
            toast.error('Please fill all the required fields.');
            return;
        }
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
            const panDetailsResponse = await fetch(`${base_url}/pan_details`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    pan_number: panNumber
                })
            });

            const panDetailsData = await panDetailsResponse.json();

            const formData = new FormData();
            formData.append('pan_image', panImage);
            setUploadingImage(true);
            const panImageResponse = await fetch(`${base_url}/upload_pan_image`, {
                method: 'POST',
                headers: {
                    Authorization: token,
                },
                body: formData
            });
            const panImageData = await panImageResponse.json();
            if (panImageData.success === 1) {
                console.log('Pan details and PAN image uploaded successfully', panImageData);
                toast.success(panImageData.message);
                // setLoading(false);

                if (panDetailsData.success === 1) {
                    toast.dismiss();
                    toast.success(panDetailsData.message);
                    navigate('/UserKyc')
                }

                else {
                    toast.dismiss();
                    toast.error(panDetailsData.message);
                    setLoading(false);
                    return;
                }


            }
            else {
                setLoading(false);
                setUploadingImage(false);
                toast.error(panImageData.message);
                return;
            }


        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setUploadingImage(false);
        }
    };


    const handlePanImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type && file.type.startsWith('image')) {
                setPanImage(file);
                setPanImageUrl(URL.createObjectURL(file));
            } else {
                toast.dismiss();
                toast.error('Please select an image file.');
            }
        }
    };


    return (
        <>
            <section className={`kyc-profile-main ${loading || uploadingImage ? 'overlay' : ''}`}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='kyc-01'>
                                    <div className='container'>
                                        <div className='kyc-row'>
                                            <div className='kyc-01-heading'>
                                                <span className="number">02</span>
                                                <div className=''>
                                                    <h5>PAN Details Upload</h5>
                                                    <p>To Verify Your PAN, Please Upload Your PAN Details</p>
                                                </div>
                                            </div>
                                            <div className='kyc-tab-main'>
                                                <div className='tab-form-main'>
                                                    <form>
                                                        <div className='form-inner-heading mt-5'>
                                                            <span><FaArrowsRotate className='drop-ic' /></span>
                                                            <h4>Fill PAN Detail</h4>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='form-group col-md-12 mt-3'>
                                                                <label>Enter PAN No</label>
                                                                <input
                                                                    type="text"
                                                                    placeholder=' i.e.   ABCDE1234F'
                                                                    className='form-control mt-2'
                                                                    value={(panNumber)}
                                                                    onChange={e => setPanNumber(e.target.value.toUpperCase())}
                                                                />
                                                            </div>
                                                            <div className='form-group col-md-12 mt-5 profile-image text-center'>
                                                                <label htmlFor="panImage">
                                                                    <img src={panImageUrl || id1} alt="PAN" />
                                                                    <input
                                                                        type="file"
                                                                        id="panImage"
                                                                        style={{ display: "none" }}
                                                                        onChange={handlePanImageChange}
                                                                    />
                                                                </label>
                                                                <div className="text-center">
                                                                    <label className="btn_upload mt-2" htmlFor="panImage">Select PAN Image</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-12 text-center mt-5">
                                                            {loading ? (
                                                                // <ColorRing color="#00BFFF" height={50} width={50} />
                                                                <div className='loader-container'>
                                                                    <PuffLoader color="#36d7b7" cssOverride={{ textAlign: 'center' }} size={30} />
                                                                </div>
                                                            ) : (
                                                                <button type='button' className="next-btn mt-2" onClick={handleSubmit}>Submit</button>

                                                            )}
                                                            {uploadingImage && loading && (
                                                                <p>Please wait, image is uploading...</p>
                                                            )}
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserPan;