import React, { useEffect, useState } from 'react'
import ProfileTopHeader from './ProfileTopHeader'
import dateFormat, { masks } from "dateformat";
import ProfileSidebar from './ProfileSidebar'
import { base_url } from '../../config/config';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

const ReferralBonus = () => {

    const [bonusData, setBonusData] = useState([]);
    const [totalBonus, setTotalBonus] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [getData, setGetData] = useState();
    const token = localStorage.getItem("token");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10); // Limit is fixed at 10 per page
    const [total, setTotal] = useState(0);



    const getMemberList = async () => {
        const response = await fetch(`${base_url}/get_referral_member`, {
            method: "Post",
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        });
        const res = await response.json();
        // console.log('responsemember1111111111', res);
        if (res.success === 1) {
            setMemberData(res.refferaldata)
            setGetData(res)
        }
    };
    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        getMember(skip);
    }, [currentPage, limit]);


    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // let getSponser = async () => {

    //     let sponser = await fetch(`${base_url}/get_sponser`, {
    //         method: "get",
    //         headers: {
    //             Authorization: token,
    //             'Content-Type': 'application/json'
    //         },
    //     });
    //     let response = await sponser.json();
    //     if (response?.success === 1) {
    //         setTotalBonus(response)

    //     } else {

    //     }
    // }

    const getMember = async (skip = 0) => {

        const response = await fetch(`${base_url}/all_token_deposit_transactions`, {
            method: "Post",
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                limit,
                skip,
                transactionType: "bonus",
            })
        });
        const res = await response.json();
        setTotal(res.total)
        // console.log('responseBonus', res);
        if (res.success === 1) {
            setBonusData(res.data)
        }
    };
    useEffect(() => {
        getMember();
        getMemberList();
    }, [])

    const capitalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/[_-]/g, ' ') // Replace underscores or hyphens with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')

    };

    return (

        <>

            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <ProfileTopHeader />
                                <div className='ref-page-title'>
                                    <h3>Invite Bonus</h3>
                                </div>

                                <div className='ref-bonus-card'>
                                    <div className='card ref-card'>
                                        <h4>Invite Bonus</h4>
                                        <p>{getData?.referralTotalAmount}<span> SHIB</span></p>
                                    </div>
                                    <div className='card ref-card'>
                                        <h4>Invited Users</h4>
                                        <p>{getData?.totalMember ? getData.totalMember : 0}</p>
                                    </div>
                                    <div className='card ref-card'>
                                        <h4>KYC Bonus</h4>
                                        <p>{(getData?.selfTotalAmount) ? (getData?.selfTotalAmount).toFixed(5) : 0} ETH</p>
                                    </div>
                                    <div className='card ref-card'>
                                        <h4>Approved Users</h4>
                                        <p>{getData?.approvedMember ? getData?.approvedMember : '0'}</p>
                                    </div>
                                </div>


                                <div className='ref-bonus-main'>
                                    <h3>Referral History</h3>
                                    <div className='ref-table-main '>
                                        <table className="table ref-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S No.</th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Currency</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date & Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bonusData.length > 0 ? (
                                                    bonusData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{(currentPage - 1) * limit + index + 1}</td>
                                                            {/* <td>{index + 1}</td> */}
                                                            <td>{item.uid}</td>
                                                            <td>{capitalizeText(item.firstname)}</td>
                                                            <td>{item.tokenName}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{capitalizeText(item.from)}</td>
                                                            <td>{dateFormat(item.createdAt, "dd-mm-yyyy,hh:mm:ss TT ")}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr className='text-center'>
                                                        <td colSpan='6'>Data Not Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div className='market-table-footer'>
                                            {bonusData.length > 0 ? (
                                                <>
                                                    <div className='total-page'>
                                                        <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                                    </div>
                                                    <div className='pagination-div'>
                                                        <nav aria-label="Page navigation example">
                                                            <ul className="pagination">
                                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                        <GrFormPrevious />
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item`}>
                                                                    <a className="page-link page-link1" >
                                                                        {currentPage}
                                                                    </a>
                                                                </li>

                                                                <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                                    <a className="page-link page-link1" onClick={handleNextPage}>
                                                                        <GrFormNext />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </>
                                            ) : (
                                                null
                                            )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default ReferralBonus