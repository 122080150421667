import React, { useEffect, useState } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import { LuArrowUpDown } from "react-icons/lu";
import toast from 'react-hot-toast';
import { base_url } from '../../config/config';
import _fetch from '../../config/api';


const TransferModal = ({ open, onCloseModal }) => {
    const token = localStorage.getItem("token");
    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#0000003b" : null,

                color: "#333333",
                fontSize: '13px'
            };
        }
    };


    const [funding, setFunding] = useState();
    const [main, setMain] = useState();
    const [trade, setTrade] = useState();

    const [showBal, setShowBal] = useState(0);

    const [selectedOption, setSelectedOption] = useState(null);
    const [fromWallet, setFromWallet] = useState('Main_wallet');
    const [toWallet, setToWallet] = useState('Funding_wallet');
    const [amount, setAmount] = useState();
    const [tId, setTid] = useState();
    const [tLabel, setTLabel] = useState();
    const [transOptionFrom, setTransOptionFrom] = useState();
    const [transOptionTo, setTransOptionTo] = useState();



    const transferFromOptions = [
        { value: 'main_wallet', label: 'Main Wallet' },
        { value: 'funding_wallet', label: 'Funding Wallet' },
        { value: 'trading_wallet', label: 'Trading Wallet' },
    ];
    const transferToOptions = [
        { value: 'main_wallet', label: 'Main Wallet' },
        { value: 'funding_wallet', label: 'Funding Wallet' },
        { value: 'trading_wallet', label: 'Trading Wallet' },
    ]


    const SwapShowBalance = async (lebel) => {
        console.log('lebel===>', lebel);
        console.log('fromWallet===>', fromWallet);
        try {
            let responseApi = await fetch(`${base_url}/useraccountbalance`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({
                    tokenName: lebel,
                    wallet_type: fromWallet
                })
            });

            const responseData = await responseApi.json();
            console.log('show-balance-history', responseData);
            if (responseData.success === 1) {
                setShowBal(responseData.balance)
            } else {
                console.error('Failed to fetch user balance:', responseData.message);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    const [coinOptions, setCoinOptions] = useState([]);
    // const [selectedCoin, setSelectedCoin] = useState(null);


    const fetchData = async () => {
        try {
            const coinResponse = await fetch(`${base_url}/token_list`, {

            });

            if (coinResponse.status === 401) {
                console.log("Unauthorized access detected!");
                return;
            }
            const coinData = await coinResponse.json();

            const coinData1 = coinData.deposit_tokens.map((coin) => ({
                value: coin._id,
                label: coin.coinName,
                image: coin.image,

            }));

            setCoinOptions(coinData1);
        } catch (error) {

        }
    }


    // transfer-api
    const transferHandel = async () => {
        // console.log('fromWallet', fromWallet);
        // console.log('toWallet', toWallet);
        // console.log('amount', amount);
        // console.log('tId', tId);
        if (!fromWallet || !toWallet || !amount || !tId) {
            toast.dismiss();
            toast.error("Please fill all  the fields");

            return;
        }
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/transfer_wallet`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sender_wallet: fromWallet,
                receiver_wallet: toWallet,
                amount: amount,
                token_id: tId
            })
        });
        let res = await response.json();
        if (res.success === 1) {
            toast.dismiss();
            toast.success(res?.message);
            onCloseModal(onCloseModal)
            setShowBal('0')
            setAmount('')
            setTid('')
            setFromWallet('')
            setToWallet('')
        } else {
            toast.dismiss();
            toast.error(res?.message);

        }


    };

    useEffect(() => {
        fetchData();

    }, [])

    const coinChangeHandle = (coin) => {
        setTid(coin.value);
        SwapShowBalance(coin.label)

    }
    return (
        <>
            <div className='transfer-modal'>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className='modal-content-main'>
                        <div className='modal-header'>
                            <h3>Transfer Fund</h3>
                        </div>
                        <div className='modal-body1'>
                            <form className='row' >
                                <div className='form-group col-md-12'>
                                    <label className='mb-2'>From:</label>
                                    <Select
                                        defaultValue={selectedOption}
                                        value={transOptionFrom}
                                        onChange={(val) => { setFromWallet(val?.value) }}
                                        options={transferFromOptions}
                                        styles={colourStyles}
                                        formatOptionLabel={option => (
                                            <div className="cselect-option">
                                                {option.label}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className='form-group col-md-12 mt-3'>
                                    <label className='mb-2'>To:</label>
                                    <Select
                                        defaultValue={selectedOption}
                                        value={transOptionTo}
                                        onChange={(val) => { setToWallet(val?.value) }}
                                        options={transferToOptions}
                                        styles={colourStyles}
                                        formatOptionLabel={option => (
                                            <div className="cselect-option">

                                                {option.label}
                                            </div>
                                        )}

                                    />

                                </div>
                                <div className='form-group col-md-12 mt-3'>
                                    <label className='mb-2'>Coins:</label>
                                    <Select
                                        value={tId?.label}
                                        // onChange={(coin) => {setTid(coin.value);setTLabel(coin.label)}}
                                        onChange={coinChangeHandle}
                                        options={coinOptions}
                                        styles={colourStyles}
                                        formatOptionLabel={option => (
                                            <div className="cselect-option">
                                                <img src={option.image} alt="coin" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                {option.label}
                                            </div>
                                        )}
                                    />
                                    <p className='mt-2'>Balances-:{showBal ? showBal : 0} <span className='ms-2'></span></p>
                                </div>
                                <div className='form-group col-md-12 mt-3'>
                                    <label className='mb-2'>Amount:</label>
                                    <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Amount' className='form-control model-input' />
                                </div>

                                <div className='form-group col-md-12 text-center mt-4'>
                                    <button type='button' className='btn modal-submit-btn' onClick={transferHandel}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
    )
}

export default TransferModal