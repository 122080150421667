// // MarketsProvider.js
// import React, { createContext, useState, useEffect } from 'react';
// import { base_url } from './config/config';

// const MarketContext = createContext();

// const MarketsProvider = ({ children }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(`${base_url}/mainMarket?limit=11`);
//                 const responseData = await response.json();
//                 if (responseData.success === 1) {
//                     setData(responseData.data);
//                 }
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     return (
//         <MarketContext.Provider value={{ data, loading, error }}>
//             {children}
//         </MarketContext.Provider>
//     );
// };

// export { MarketContext, MarketsProvider };

// MarketsProvider.js
// import React, { createContext, useState, useEffect } from 'react';
// import { base_url } from './config/config';

// const MarketContext = createContext();

// const MarketsProvider = ({ children }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(`${base_url}/mainMarket?limit=11`);
//                 const responseData = await response.json();
//                 if (responseData.success === 1) {
//                     setData(responseData.data);
//                 }
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     return (
//         <MarketContext.Provider value={{ data, loading, error }}>
//             {children}
//         </MarketContext.Provider>
//     );
// };

// export { MarketContext, MarketsProvider };
// import React, { createContext, useState, useEffect } from 'react';
// import { base_url } from './config/config';

// const MarketContext = createContext();

// const MarketsProvider = ({ children }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const fetchData = async () => {
//         try {
//             const response = await fetch(`${base_url}/mainMarket?limit=11`);
//             const responseData = await response.json();
//             if (responseData.success === 1) {
//                 setData(responseData.data);
//             }
//         } catch (error) {
//             setError(error.message);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//     }, []);
// // console.log("data-------",data);
//     return (
//         <MarketContext.Provider value={{ data, loading, error }}>
//             {children}
//         </MarketContext.Provider>
//     );
// };

// export { MarketContext, MarketsProvider };


import React, { createContext, useState, useEffect } from 'react';
import { base_url } from './config/config';

const MarketContext = createContext();

const MarketsProvider = ({ children, symbol }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nId, setNId] = useState([]);

    // console.log("nid", nId)
    const getData = async () => {
        try {
            const res = await fetch(`${base_url}/pairCurrency`,
                {
                    method: "GET"
                }
            )
            const response = await res.json();
            // console.log("response", response)
            if (response.success === 1) {
                setNId(response.data[0]._id)
                fetchData(response.data[0]._id)
            }
        } catch {

        }

    }



    const fetchData = async (token_id) => {
        try {
            const response = await fetch(`${base_url}/mainMarket?limit=10`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify({
                        tokenId: token_id
                    })
                });
            const responseData = await response.json();

            if (responseData.success === 1) {

                setData(responseData.data);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [symbol]);

    // console.log('data',data);
    return (
        <MarketContext.Provider value={{ data, loading, error, symbol }}>
            {children}
        </MarketContext.Provider>
    );
};

export { MarketContext, MarketsProvider };
