import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/img/Logo/logo-white.png';
import logoBlack from '../assets/img/Logo/logo-black.png';
import { IoMoon, IoSunny } from "react-icons/io5";
import { RiArrowDropDownFill } from "react-icons/ri";
import { FaBars, FaUser } from 'react-icons/fa';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { IoIosLogOut, IoMdMail } from 'react-icons/io';
import { ProfileContext } from '../Profilecontextapi';
import LogoutModal from './LogoutModal';

const Header = ({ toggleMode, theme }) => {
    const { userData } = useContext(ProfileContext);

    // useEffect(() => {
    //     getUserDetail();
    // }, []);


    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const token = localStorage.getItem('token');
    let location = useLocation();
    const navigate = useNavigate();

    // const logout = () => {
    //     // handleLogout();

    //     navigate('/');
    // }
    const logout = () => {
        const cleartoken = localStorage.clear('token');
        setIsLogoutModalOpen(false);
        navigate('/');
    };

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };

    const handleClick = () => {
        const offcanvasElement = document.querySelector('.offcanvas');
        offcanvasElement.setAttribute('data-bs-dismiss', 'offcanvas');
        // offcanvasElement.classList.remove('show');
        offcanvasElement.setAttribute('aria-hidden', 'true');
    };

    // const handleClick = () => {

    //     const offcanvasElement = document.querySelector('.remove');
    //     offcanvasElement.setAttribute('data-bs-dismiss', 'offcanvas');
    //     offcanvasElement.setAttribute('aria-label', 'Close');
    // };


    return (
        <>
            <section className='top-header'>
                <nav className="navbar navbar-expand-lg  navbar-1">
                    <div className="container">
                        <NavLink className="navbar-brand header-logo" to="/">
                            <span className='logo-span'><img src={theme === 'dark' ? logo : logoBlack} alt="logo-image" /></span>
                        </NavLink>
                        <button className="navbar-toggler navbar-toggler-dnone" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <FaBars className='bars' />
                            </span>
                        </button>

                        <div className="collapse navbar-collapse navbar-collapse1" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 header-ul">
                                <li className={location.pathname === "/" ? "nav-item h-nav-link  activeLink" : "nav-item h-nav-link"}>
                                    <NavLink className="nav-link active" aria-current="page" to="">Home</NavLink>
                                </li>

                                {token && (
                                    <>
                                        <li className={location.pathname === "/dashboard" ? "nav-item h-nav-link  activeLink" : "nav-item h-nav-link"}>
                                            <NavLink className="nav-link active" aria-current="page" to="/dashboard">Dashboard</NavLink>
                                        </li>
                                    </>
                                )}
                                <li className={location.pathname === "/market" ? "nav-item h-nav-link  activeLink" : "nav-item h-nav-link"}>
                                    <NavLink className="nav-link" to='/market'>Market</NavLink>
                                </li>
                                {/* <li className={location.pathname === "/trade" ? "nav-item h-nav-link  activeLink" : "nav-item h-nav-link"}>
                                    <NavLink className="nav-link" to='/trade'>Exchange</NavLink>
                                </li> */}
                                <li className={location.pathname === "/referral" ? "nav-item h-nav-link  activeLink" : "nav-item h-nav-link"}>
                                    <NavLink className="nav-link " to='/referral' tabindex="-1" aria-disabled="true">Earnings</NavLink>
                                </li>

                                <li className="nav-item h-nav-link-dropdown">
                                    <NavLink className="nav-link h-dropdown" tabindex="-1" aria-disabled="true">
                                        <span>More <RiArrowDropDownFill className='h-drop-icon' /></span>
                                        <div className='h-dropdown-menu'>
                                            <ul>
                                                <li><NavLink to="/faq">FAQ</NavLink></li>
                                                <li><NavLink to="/support">Support</NavLink></li>
                                                <li><NavLink to="/security">Security</NavLink></li>
                                                <li><NavLink to="/download">Downloads</NavLink></li>
                                            </ul>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                            <div className='header-right-div d-flex'>
                                <div className='header-btn-main '>
                                    {token ? (
                                        <div className=" h-nav-link-dropdown user-header-dropdown">
                                            <NavLink className="nav-link h-dropdown" tabindex="-1" aria-disabled="true">
                                                <span><FaUser className='user-icon-h1' /><span className='ms-1'>User ID-{userData?.username}</span></span>
                                                <div className='h-dropdown-menu user-dropdown-menu'>
                                                    <ul>
                                                        <li>
                                                            <span className='d-icon'><IoMdMail /> </span><span className='email-span'> {userData ? userData?.email : ''}</span>

                                                        </li>
                                                        <li>
                                                            <NavLink to="/kycprofiledetail">
                                                                <span className='drop-d-flex1'><FaUser className='d-icon' /></span>My Account
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink className="" onClick={() => setIsLogoutModalOpen(true)}>
                                                                <span className='drop-d-flex1'><IoIosLogOut className='d-icon' /></span> Logout
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ) : (
                                        <>
                                            <NavLink className=" btn-1 login-btn1" to="/login">Log In</NavLink>
                                            <NavLink className=" btn-1 sign-btn1" to="/signup">Sign Up</NavLink>
                                        </>
                                    )}
                                </div>

                                <div className='theme-button'>
                                    <span className="mode-btn" onClick={toggleMode}>
                                        {theme === 'dark' ? <IoSunny className="icon-light move-sun-icon" /> : <IoMoon className="icon-dark move-moon-icon" />}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-toggle'>
                            <div className='mobile-toggle-flex'>
                                <span className="mode-btn" onClick={toggleMode}>
                                    {theme === 'dark' ? <IoSunny className="icon-light move-sun-icon" /> : <IoMoon className="icon-dark move-moon-icon" />}
                                </span>
                                <button class="btn mobile-toggle-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><FaBars /></button>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>


            <section className='mobile-header'>
                <div class="offcanvas offcanvas-start offcanvas-body" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div class="offcanvas-header">
                        <img src={theme === 'dark' ? logo : logoBlack} alt="logo-image" />
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        {token && (
                            <div className=" h-nav-link-dropdown user-header-dropdown">
                                <NavLink className="nav-link h-dropdown" tabindex="-1" aria-disabled="true">
                                    <span><FaUser className='user-icon-h1' /></span>
                                    <div className='h-dropdown-menu user-dropdown-menu'>
                                        <ul>
                                            <li>
                                                <span>{userData ? userData.email : ''}</span>
                                                <br />
                                                <span>Uid - {userData ? userData.username : ''}</span>
                                            </li>
                                            <li>
                                                <NavLink to="/kycprofiledetail">
                                                    <span className='drop-d-flex1'><FaUser className='d-icon' /></span>My Account
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="" onClick={() => setIsLogoutModalOpen(true)}>
                                                    <span className='drop-d-flex1'><IoIosLogOut className='d-icon' /></span> Logout
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    <div class="offcanvas-body mobile-menu">
                        <ul>
                            <li><NavLink to="/" onClick={handleClick} className='remove'>Home</NavLink></li>
                            {token && (
                                <>
                                    <li><NavLink to="/dashboard" onClick={handleClick}>Dashboard</NavLink></li>
                                </>
                            )}
                            <li><NavLink to="/market" onClick={handleClick}>Market</NavLink></li>
                            {/* <li><NavLink to="/trade">Exchange</NavLink></li> */}
                            <li><NavLink to="/referral" onClick={handleClick}>Earnings</NavLink></li>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item  accordion-item-userheader">
                                    <h2 class="accordion-header accordion-h1" id="flush-headingOne">
                                        <button class="accordion-button collapsed accordion-button-h1" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <span>More</span>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body accordion-body-h1">
                                            <li><NavLink to="/faq" onClick={handleClick}><span className='me-1'><MdKeyboardDoubleArrowRight /></span>FAQ</NavLink></li>
                                            <li><NavLink to="/support" onClick={handleClick}><span className='me-1'><MdKeyboardDoubleArrowRight /></span>Support</NavLink></li>
                                            <li><NavLink to="/security" onClick={handleClick}><span className='me-1'><MdKeyboardDoubleArrowRight /></span>Security</NavLink></li>
                                            <li><NavLink to="/download" onClick={handleClick}><span className='me-1'><MdKeyboardDoubleArrowRight /></span>Downloads</NavLink></li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mobile-footer'>
                                {token ? (
                                    <>
                                        <button className=" btn-1 login-btn1 w-100" to="/dashboard" onClick={() => setIsLogoutModalOpen(true)}>Logout</button>
                                    </>
                                ) : (
                                    <>
                                        <NavLink className=" btn-1 login-btn1 m-login-btn" to="/login">Login</NavLink>
                                        <NavLink className=" btn-1 sign-btn1 m-login-btn" to="/signup">Sign Up</NavLink>
                                    </>
                                )}
                            </div>
                        </ul>
                    </div>
                </div>
            </section>
            <LogoutModal
                isOpen={isLogoutModalOpen}
                onCancel={() => setIsLogoutModalOpen(false)}
                onConfirm={logout}
            />
        </>
    );
}

export default Header;
