import React, { useEffect, useContext, useState } from 'react'
import { base_url } from '../../config/config'

import ProfileTopHeader from './ProfileTopHeader';
import { ProfileContext } from '../../Profilecontextapi';
import { NavLink } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';



const UserProfilePage = () => {
    const { userData, loading } = useContext(ProfileContext);
    // useEffect(() => {
    //     getUserDetail();
    // }, []);

    return (
        <>
            <div className='user-page-main'>
                <ProfileTopHeader />
                {/* {(userData?.email_verified !== true || userData?.phone_verified !== true) ?
                    (<div className='show-popup'>
                        <h4>  <FaCircle className="text-danger-glow blink" /> Please Verify Your Account with Your {userData?.email_verified ? 'Phone' : 'Email'}</h4>
                        <NavLink to="/user-verify" className=" btn-1 login-btn1">Verify Your {userData?.email_verified ? 'Phone ' : 'Email'} </NavLink>

                    </div>) :
                    ''
                } */}
                {/* Please Verify Your Account Via Phone instead of Please Your Account Verify With Your Phone */

                    // Please Verify Your Account Via Email

                }
                <div className='user-page-title'>
                    <div>
                        <h5>My Profile</h5>
                        <p>UID:{userData ? userData.username : ''}</p>
                    </div>
                    <div className='status-div'>
                        <span>KYC Status:-</span>
                        <span className='show-status'>{userData ? userData.kycStatus : 'Not Submitted'}</span>
                    </div>
                </div>
                <div className='row mt-5 mb-5 row-margin-20'>
                    <div className='col-md-6'>
                        <div className='form-group userdata-group'>
                            <label>First Name</label>
                            <li>{userData ? userData.firstname : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group userdata-group'>
                            <label>Last Name</label>
                            <li>{userData ? userData.lastname : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            <label>Email</label>
                            <li>{userData ? userData.email : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            <label>Phone</label>
                            <li>{userData ? userData.phone : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            <label>Address</label>
                            <li>{userData ? userData.address : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            <label>PAN Detail</label>
                            <li>{userData ? userData.pan_number : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            <label>{userData ? userData.documentType : ''} Detail</label>
                            <li>{userData ? userData.documentNumber : ''}</li>
                        </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                        <div className='form-group userdata-group'>
                            {/* <label>{userData ? userData.documentType : 'Document'} Detail</label> */}
                            {/* <img src={userData?.documentBack} alt="no image- found" width='100px' height='100px'/> */}
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default UserProfilePage