

import React, { useEffect, useContext, useState } from 'react';
import { MarketContext } from '../Marketcontextapi';
import { base_url } from '../config/config';
import toast from "react-hot-toast";

import { OrderContext } from './OrderContext';
import _fetch from '../config/Fetch'
const TradingPage = ({ symbol }) => {
  const [latestPrice, setLatestPrice] = useState(null);
  const [orderBookData, setOrderBookData] = useState([]);
  const [latestAmount, setLatestAmount] = useState(null);
  const [latestTotal, setLatestTotal] = useState(null);
  const { data, error, loading } = useContext(MarketContext);
  const { refreshOrder } = useContext(OrderContext);
  // const handleRowClick = (data) => {
  //   refreshOrder(data); // Store in context and refresh

  // }
  const tokenData = data.find((item) => item.symbol.toLowerCase() === symbol.toLowerCase());
  // console.log({ tokenData });
  const startFetchingCustomTokenData = (tokenId) => {
    const fetchData = async () => {
      await fetchCustomTokenData(tokenId);
      setTimeout(() => fetchData(), 30000);
    };

    fetchData();
  };
  useEffect(() => {
    if (!data || loading || error) return;

    const tokenData = data.find((item) => item.symbol.toLowerCase() === symbol.toLowerCase());
    console.log({ tokenData });

    if (tokenData) {


      if (tokenData?.tokenType && tokenData?.tokenType === "CUSTOM") {

        startFetchingCustomTokenData(tokenData.id);
      } else {
        setOrderBookData([]);
        const token = symbol.toLowerCase() + 'usdt';
        const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${token}@trade`);

        ws.onmessage = (message) => {
          try {
            const data = JSON.parse(message.data);
            const total = parseInt(data.T);
            const price = parseFloat(data.p);
            const amount = parseFloat(data.t);

            setLatestPrice(price);
            setLatestAmount(total);
            setLatestTotal(amount);
            setOrderBookData((prevData) => Array.isArray(prevData) ? [...prevData, { price, total, amount }] : [{ price, total, amount }])
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        return () => {
          ws.close();
        };
      }
    }
  }, [symbol, data, loading, error]);



  const fetchCustomTokenData = async (_id) => {
    try {
      const requestData = {
        tokenId: _id
      };
      let response = await _fetch(`${base_url}/trade-book`, 'post', requestData);
      if (response.success === 1) {
        setOrderBookData(response?.data);
      } else {
        toast.dismiss();
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const getPriceChangeClass = (price) => {
    if (price > latestPrice) {
      return 'price-up';
    } else if (price < latestPrice) {
      return 'price-down';
    } else {
      return '';
    }
  };



  return (
    <>
      <div className='orderbook-card'>
        <div className='order-header'>
          <h4>Order Book</h4>
        </div>
        <div className='group-div'>
          <p>Group By</p>
          <div className=''>
            <select className='order-select'>
              <option>0.00001</option>
              <option>0.0001</option>
              <option>0.001</option>
              <option>0.01</option>
            </select>
          </div>
        </div>
        <div className='trade-table1 table-scroll2'>
          {tokenData?.tokenType && tokenData?.tokenType === "CUSTOM" ? (

            <div className='row'>
              <div className='col-lg-6'>

                <div className='order-header'>
                  <h4>Buy</h4>
                </div>
                <table className="table tradeing-table-row custom-table">
                  <thead>
                    <tr>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderBookData?.buyBook && orderBookData?.buyBook.length > 0 ? (
                      orderBookData.buyBook?.map((data, index) => (
                        <tr key={index}
                        //                 onClick={() => handleRowClick(data)}
                        // style={{ cursor: 'pointer' }}
                        >
                         <td>{Number(data.price)?.toFixed(2)}</td>
                          <td> {Number(data.quantity)?.toFixed(4)}</td>
                          <td>{Number(data.usdValue)?.toFixed(4)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
                          No data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='col-lg-6'>

                <div className='order-header'>
                  <h4>Sell</h4>
                </div>
                <table className="table tradeing-table-row custom-table">
                  <thead>
                    <tr>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderBookData?.sellBook && orderBookData.sellBook.length > 0 ? (
                      orderBookData.sellBook.map((data, index) => (
                        <tr key={index}>
                          <td>{Number(data.price)?.toFixed(2)}</td>
                          <td>
                            {Number(data.quantity)?.toFixed(4)}
                          </td>
                          <td>
                          {Number(data.usdValue)?.toFixed(4)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
                          No data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (

            <>
              <table className="table tradeing-table-row">
                <thead>
                  <tr>
                    <th>Price ({symbol.toUpperCase()}USDT)</th>
                    <th>Amount ({symbol.toUpperCase()}USDT)</th>
                    <th>Total (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  {orderBookData?.length > 0 ? (
                    orderBookData.slice().reverse().map((data, index) => (
                      <tr key={index} className={getPriceChangeClass(data.price)}>
                        <td>{data.price}</td>
                        <td>{data.amount}</td>
                        <td>{data.total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
                        No data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>

      </div>
    </>
  );
};

export default TradingPage;

