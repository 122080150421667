import React, { useContext, useEffect } from 'react';
import ProfileSidebar from './ProfileSidebar';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import ProfileTopHeader from './ProfileTopHeader';
import { ProfileContext } from '../../Profilecontextapi';
import { FaCircle } from 'react-icons/fa';

const UserKycProfile = () => {

    const { userData, loading } = useContext(ProfileContext);
    return (
        <>
            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <div className='user-page-main'>
                                    <ProfileTopHeader />

                                    {/* {(userData?.email_verified !== true || userData?.phone_verified !== true) ?
                                        (<div className='show-popup'>
                                            <h4>  <FaCircle className="text-danger-glow blink" />Please Your Account verify with Your {userData?.email_verified ? 'Phone' : 'Email'}</h4>
                                            <NavLink to="/user-verify" className=" btn-1 login-btn1">Verify Your Account </NavLink>

                                        </div>) :
                                        ''
                                    } */}
                                    <div className='user-page-title'>
                                        <div>
                                            <h5>KYC Verification</h5>
                                        </div>
                                    </div>

                                    <div className='kycpage-header'>
                                        <div className='kyc-header-flex'>
                                            <div className='form-group userdata-group'>
                                                <label>First Name :-</label>
                                                <span>{userData ? userData.firstname : ''}</span>
                                            </div>
                                            <div className='form-group userdata-group'>
                                                <label>UID :-</label>
                                                <span>{userData ? userData.username : ''}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='kycPage-body1'>
                                        <li><span className="kp-body1"><IoIosArrowRoundForward className='kp-icon' /></span></li>
                                        {/* <h2>START YOUR KYC PROCESS</h2> */}
                                        <h2>
                                            {
                                                userData?.kycStatus === 'APPROVED' ? "Your Kyc Approved"
                                                    : userData?.kycStatus === 'APPLIED' ? "YOUR KYC IN PROCESSING"
                                                        : "START YOUR KYC PROCESS"
                                            }
                                        </h2>
                                        <div>

                                            {userData?.kycStatus === 'APPROVED' ? (<NavLink to="/kycprofiledetail"> KYC Approved</NavLink>)
                                                : userData?.kycStatus === 'APPLIED' ? (<NavLink to="/kycprofiledetail">KYC in Processing</NavLink>) :
                                                    (<NavLink to="/userdetail">Verify Your KYC</NavLink>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserKycProfile