
import React, { useState, useEffect } from 'react';
import { base_url } from '../config/config';
import dateFormat, { masks } from "dateformat";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { NavLink } from 'react-router-dom';
import { Loader } from 'rsuite';
import toast from "react-hot-toast";
import _fetch from '../config/Fetch'
const TradeFooterTable = () => {
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('COMPLETED');

    // console.log('footer-order', orders);
    const Token = localStorage.getItem('token');
    const fetchOrders = async () => {
        try {
            const response = await fetch(`${base_url}/trading_orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: Token
                },
                body: JSON.stringify({ status: activeTab.toUpperCase() })
            });
            // console.log('response', response);
            const data = await response.json();
            // console.log('data--->aaaa', data);
            if (data?.success === 1) {
   
                setOrders(data.orders);

            }

        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };
    useEffect(() => {


        fetchOrders();
    }, [activeTab]);


    const handledashboard = async (_id) => {
        console.log({ _id });

        try {

            const requestData = {
                order_id: _id
            };

            let response = await _fetch(`${base_url}/cancel_order`, 'post', requestData);

            if (response.success === 1) {
                // setData(response);
                toast.dismiss()
                toast.success(response?.message);
                fetchOrders();

            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };
    // console.log('order---', orders);
    return (
        <>
            <div className='container-fluid'>
                <div className='footer-trade-table'>
                    <nav>
                        <div className="nav nav-tabs ftt-nav" id="nav-tab" role="tablist">
                            <button
                                className={`nav-link ${activeTab === 'PENDING' ? 'active' : ''} ftt-link`}
                                id="nav-pending-tab"
                                onClick={() => handleTabChange('PENDING')}
                                aria-selected={activeTab === 'PENDING'}
                            >
                                Pending
                            </button>
                            <button
                                className={`nav-link ${activeTab === 'PARTIALFILLED' ? 'active' : ''} ftt-link`}
                                id="nav-open-tab"
                                onClick={() => handleTabChange('PARTIALFILLED')}
                                aria-selected={activeTab === 'PARTIALFILLED'}
                            >
                                Open
                            </button>
                            <button
                                className={`nav-link ${activeTab === 'COMPLETED' ? 'active' : ''} ftt-link`}
                                id="nav-completed-tab"
                                onClick={() => handleTabChange('COMPLETED')}
                                aria-selected={activeTab === 'COMPLETED'}
                            >
                                Completed
                            </button>
                            <button
                                className={`nav-link ${activeTab === 'CANCELLED' ? 'active' : ''} ftt-link`}
                                id="nav-CANCELLED-tab"
                                onClick={() => handleTabChange('CANCELLED')}
                                aria-selected={activeTab === 'CANCELLED'}
                            >
                                Cancelled
                            </button>
                        </div>
                    </nav>

                    <div className="tab-content ftt-content-body p-1" id="nav-tabContent">
                        <div className={`tab-pane ${activeTab === 'PENDING' ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-pending-tab">
                            {/* Tab content for Pending */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.length > 0 ? orders.map((order, index) => (

                                                (<tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>

                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                    <button
                                                        className="btn sell-btn "
                                                        type='button'
                                                        onClick={() => handledashboard(order._id)}
                                                    >
                                                        cancel
                                                    </button>
                                                </tr>)
                                            )) :
                                                (<tr className='text-center'>
                                                    <td colSpan='6'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'PARTIALFILLED' ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-open-tab">
                            {/* Tab content for Open */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.length > 0 ? orders?.map((order, index) => (
                                                <tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>

                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                    <button
                                                        className="btn sell-btn "
                                                        type='button'
                                                        onClick={() => handledashboard(order._id)}
                                                    >
                                                        cancel
                                                    </button>
                                                </tr>
                                            ))
                                                :
                                                (<tr className='text-center'>
                                                    <td colSpan='6'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'COMPLETED' ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-completed-tab">
                            {/* Tab content for Completed */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.length > 0 ? orders?.map((order, index) => (

                                                <tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>
                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                </tr>
                                            ))
                                                :
                                                (<tr className='text-center'>
                                                    <td colSpan='7'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane ${activeTab === 'CANCELLED' ? 'active show' : ''}`} id="nav-CANCELLED" role="tabpanel" aria-labelledby="nav-CANCELLED-tab">
                            {/* Tab content for Completed */}
                            <div className='col-md-12'>
                                <div className='market-table trade-m-table'>
                                    <table className="table mt-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Currency Symbol</th>
                                                <th scope="col"> Coin Pending Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">  Coin Quantity</th>
                                                <th scope="col">Coin Symbol</th>
                                                <th scope="col">Time</th>
                                                <th>View History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.length > 0 ? orders?.map((order, index) => (

                                                <tr key={index}>
                                                    <td>{order.pairCurrencySymbol}</td>
                                                    <td>{order.tokenPendingquantity}</td>
                                                    <td>{order.tokenPrice}</td>
                                                    <td>{order.tokenQuantity}</td>
                                                    <td>{order.tokenSymbol}</td>
                                                    <td>{dateFormat(order.createdAt, "dd-mm-yyyy , mm-hh tt")}</td>
                                                    <td><NavLink to={`/tradeorderhistory?orderId=${order._id} `} className='btn-1 login-btn1'>View</NavLink></td>
                                                </tr>
                                            ))
                                                :
                                                (<tr className='text-center'>
                                                    <td colSpan='7'> <Loader size="md" speed="fast" />Data Not Found</td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* //historymodal */}

            <div className='transfer-modal'>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className='modal-content-main'>
                        <div className='modal-header'>
                            <h3>History</h3>
                        </div>
                        <div className='modal-body1'>

                        </div>
                    </div>
                </Modal>
            </div>

        </>
    );
};

export default TradeFooterTable;
