import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const InnerHeader = () => {
    let location = useLocation();
    return (
        <>
            <section className='inner-header'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='inner-menu'>
                                <div className='inner-menu-sm'>
                                    <ul>
                                        <li className={location.pathname === "/deposit" && "active-lish-history"}><NavLink to="/deposit">Deposit</NavLink></li>
                                        <li className={location.pathname === "/withdraw" && "active-lish-history"}><NavLink to="/withdraw">Withdrawal </NavLink></li>
                                        <li className={(location.pathname == "/deposithistory" || location.pathname == "/withdrawhistory") && "active-lish-history"}><NavLink to="/deposithistory">Deposit & Withdrawal History</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InnerHeader