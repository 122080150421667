import React from 'react'
import ProfileSidebar from './ProfileSidebar'
import UserProfilePage from './UserProfilePage'
import ProfileTopHeader from './ProfileTopHeader'

const KycProfileMain = () => {
    return (
        <>
            <section className='kyc-profile-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-3 col-xl-dnone'>
                            <div className='profile-sidebar-main'>
                                <ProfileSidebar />
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-9'>
                            <div className='profile-main-div'>
                                <UserProfilePage />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KycProfileMain