import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaUserPlus } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import setup from '../../assets/img/set-up.png'
import countries from '../../assets/img/countries.png'
import bannerimg from '../../assets/img/carre_img.png'
const Carre = () => {
    return (
        <>

<section className="carre01">
        <div className="container">
            <div className="row banner">
                <div className="col-lg-6 col-md-6 align-self col_b_1">
                    <div className="col_1">
                        <h4 className="sb market_sub_title outfit">Company</h4>
                        <h1 className="banner_heading w outfit">Bit by bit, making a bigger bit</h1>
                        <p className="banner_text w outfit">Slowly but surely, we're building something epic. And you're thinking, 'Wow, wouldn't it be sick to work at Bitnetto?' You're damn right it would be.</p>
                        <a href="#" className="banner_btn outfit ">Find Open Jobs</a>
                    </div>
                </div>  
                <div className="col-lg-6 col-md-6 text-center py-3">
                    <img className='banner_img' src={bannerimg}></img>
                </div>
            </div>
        </div>
    </section>
           
            <section className="carre02">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='counter_box_main'>
                                <div className='counter_box text-center'>
                                {/* <img src={countries}></img> */}
                                    <h4 className='counter_title w text-center outfit'>150 +</h4>
                                    <p className='sb counter_text outfit text-center'>Countries / Regions</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='counter_box_main'>
                                <div className='counter_box text-center'>
                                {/* <img src={countries}></img> */}
                                    <h4 className='counter_title w text-center outfit'>2,500 +</h4>
                                    <p className='sb counter_text outfit text-center'>Employees</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='counter_box_main'>
                                <div className='counter_box text-center'>
                                {/* <img src={countries}></img> */}
                                    <h4 className='counter_title w text-center outfit'>45 M +</h4>
                                    <p className='sb counter_text outfit text-center'>Customers</p>
                                </div>
                                <div className='counter_border'></div>
                                <div className='counter_border2'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sec04">
        <div className="container">
            <h4 className="sb text-center market_sub_title outfit">Join our team</h4>
            <h2 className="w text-center market_title outfit">We always seek fresh faces and top <br></br>talent to join our crew</h2>
            <p className="banner_text w outfit text-center gr">We don't care about your fancy degrees or resume fluff, we want people with attitude, drive, and out-of-the-box thinking.<br></br> At Bitnetto, we don't just grind hard - we party hard too.</p>
            <div className="margin_top ">
                <div className="row mt-5">
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw1 text-center">
                            <div className="jaw_icon_box_img">
                               <div className='jaw_icon_box_img_box'>
                                        <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">Tech Leach</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">A leader to manager a group of developers </p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw2 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">New Job here</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">Summary</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw3 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">HR Office Updated</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">Summary here</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw4 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">HR Office</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">This is a small summary</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw5 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">Project Manager</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">This is a small summary</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw6 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">Developer</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">This is a small summary</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw7 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">Test</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">This is a small summary</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="icon_box-jaw icon_box-jaw8 text-center">
                            <div className="jaw_icon_box_img">
                                <div className='jaw_icon_box_img_box'>
                                    <img src={setup}></img>
                                </div>
                            </div>
                            <h5 className="profile_box_title text-center outfit w">Te</h5>
                            {/* <p className="banner_text gr outfit text-center mb-0">This is a small summary</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center mt-4'>
            <a href="#" className="banner_btn outfit ">Browse all positions available</a>
            </div>
        </div>
    </section>
        </>
    )
}

export default Carre
