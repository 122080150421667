import React, { useEffect, useState } from 'react'
import OverHeader from './OverHeader'
import { useLocation } from 'react-router-dom';
import { base_url } from '../../config/config';
import dateFormat, { masks } from "dateformat";
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

const TokenHistort = () => {
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const tokenId = params.get('tokenId');
    const [tokenHistory, setToknHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0);

    console.log('tokenId', tokenId);

    const getTokenData = async (skip = 0) => {
        const token = localStorage.getItem("token");
        const response = await fetch(`${base_url}/token_transation`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tokenId: tokenId,
                limit: limit,
                skip: skip,
                walletType: "main_wallet"
            })
        });
        const data = await response.json();
        console.log('getTokenData--->', data);
        if (data.success === 1) {
            setTotal(data.total)
            // console.log('data--12',data.wallets);
            setToknHistory(data.data)

        } else {
            console.error('Error:', response.status);
        }
    };
    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        getTokenData(skip);
    }, [currentPage, limit]);

    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const capitalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/[_-]/g, ' ') // Replace underscores or hyphens with spaces
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    };

    useEffect(() => {
        getTokenData();
    }, []);

    console.log('tokenHistory---->', tokenHistory);
    return (
        <>
            <OverHeader />

            <section className='token-history-main'>
                <div className="container">
                    <div className='mt-5 '>
                        <h4>Token Transaction History</h4>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 mb-5'>
                            <div className='market-table'>

                                <table class="table mt-table">
                                    <thead>
                                        <tr>
                                            <th>S No.</th>
                                            <th>Token Name</th>
                                            <th>Amount</th>
                                            <th>Mode</th>
                                            <th >Transaction Type</th>
                                            <th >Wallet Type </th>
                                            <th>Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tokenHistory.length > 0 ? tokenHistory?.map((item, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{(currentPage - 1) * limit + index + 1}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{item ? item.tokenName : ''}</td>
                                                        <td className={item.amount > 0 ? 'green-text' : 'red-text'}>{item ? item.amount : ''}</td>
                                                        <td>
                                                            {item.amount > 0 ? 'Credit' : 'Debit'}
                                                        </td>
                                                        <td>{item ? capitalizeText(item.transactionType) : ''}</td>
                                                        <td >
                                                            {item ? capitalizeText(item.walletType) : ''}
                                                        </td>
                                                        <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>

                                                    </tr>
                                                </>
                                            )
                                        }) :
                                            (<tr className='text-center'>
                                                <td colSpan='6'>Data Not Found</td>
                                            </tr>)
                                        }
                                        {/* {tokenHistory.map((item, index) => {
                                            console.log('map-item-data', item);
                                            return (
                                                <tr key={index}>
                                                    <td >

                                                        {item ? item.walletType : ''}

                                                    </td>
                                                    <td>{item ? item.amount : ''}</td>
                                                    <td>{item ? item.tokenName : ''}</td>
                                                    <td>{item ? item.transactionType : ''}</td>
                                                    <td>{dateFormat(item.createdAt, "dd-mm-yyyy , hh:mm:ss TT")}</td>

                                                </tr>
                                            )
                                        })} */}
                                    </tbody>
                                </table>
                                <div className='market-table-footer'>
                                    {tokenHistory.length > 0 ? (
                                        <>
                                            <div className='total-page'>
                                                <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                            </div>
                                            <div className='pagination-div'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                <GrFormPrevious />
                                                            </a>
                                                        </li>

                                                        <li className={`page-item`}>
                                                            <a className="page-link page-link1" >
                                                                {currentPage}
                                                            </a>
                                                        </li>

                                                        <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handleNextPage}>
                                                                <GrFormNext />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </>
                                    ) : (
                                        null
                                    )
                                    }
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </section >


        </>
    )
}

export default TokenHistort