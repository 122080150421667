import React, { useState, useEffect,useContext } from 'react';
import { base_url } from '../config/config';
import { MarketContext } from '../Marketcontextapi';
import _fetch from '../config/Fetch'

const RecentTrades = ({ symbol }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data, error } = useContext(MarketContext);
  const tokenData = data.find((item) => item.symbol.toLowerCase() === symbol.toLowerCase());
  
  const tokenId = tokenData?._id; 
  const fetchTransactions = async (tokenId) => {
    // if (!tokenId) ;
    console.log({tokenId});
    
    try {
      const requestData = {
        tokenId: tokenId
      };
      const response = await _fetch(`${base_url}/recent_trades`,'post', requestData);
      
      if (response.success === 1) {
        setTransactions(response.Data);
      } else {
        console.error('Error:', response.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (tokenId) {
      fetchTransactions(tokenId);
    // }
  }, [tokenId]);

  return (
    <div className='orderbook-card'>
      <div className='order-header'>
        <h4>Recent Trades</h4>
      </div>

      <div className='trade-table1 table-scroll3'>
        <table className="table tradeing-table-row">
          <thead>
            <tr>
              <th scope="col">Price (USD)</th>
              <th scope="col">Amount (AAVE)</th>
              <th scope="col">Total (USD)</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>Loading...</td>
              </tr>
            ) : (
              transactions && transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  return (
                    <tr key={index} >
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{transaction.tokenPrice?.toFixed(2)}</td>
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{transaction.tokenQuantity?.toFixed(2)}</td>
                      <td className={transaction.mode === 'BUY' ? 'green-text' : 'red-text'}>{(transaction.tokenPrice * transaction.tokenQuantity)?.toFixed(2)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No transactions available</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTrades;
