import { createContext, useState } from "react";

export const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
console.log({selectedOrder});

  const refreshOrder = (newOrder) => {
    setSelectedOrder(null); 
    setTimeout(() => {
      setSelectedOrder(newOrder); 
    }, 100); 
  };

  return (
    <OrderContext.Provider value={{ selectedOrder, refreshOrder }}>
      {children}
    </OrderContext.Provider>
  );
};