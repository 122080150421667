import React from 'react'
import sup from '../../assets/img/fees.png'
import { HiOutlineCheckBadge } from "react-icons/hi2";
const Fees = () => {
  return (
    <>
      <section className="fees01">
            <div className="container">
            <div className="row banner column-reverse">
                <div className="col-lg-6 col-md-6 align-self">
                    <div className="col_1"> 
                        <h1 className="w banner_heading outfit">Fees</h1>
                        <p className="banner_text w outfit">CV Trade Network was founded in 2024 by a team of young and passionate crypto enthusiasts, all of whom have a background in cutting-edge technologies. Our team members are committed to exploring the vast potential of crypto and blockchain technologies to revolutionize the financial industry.</p>
                        <p className="banner_text w outfit">Before starting CV Trade Network, our team members have developed multiple tech products that have assisted consumers in making informed decisions about various industries. However, our focus on crypto and blockchain led us to establish CV Trade Network, a platform for trading cryptocurrencies.</p>
                    </div>
                </div>  
                <div className="col-lg-6 col-md-6 text-center py-3">
                    <img className='banner_img_faq fees01_img' src={sup}></img>
                </div>
            </div>
        </div>
        </section>
        <section className="fees02">
                <div className='container'>
                        <p className="banner_text w outfit d-flex"> <HiOutlineCheckBadge className='sb fees_icon' /> At CV Trade Network, we firmly believe that cryptocurrencies and blockchain technology are the future of money. We are committed to promoting an open, transparent, and clear communication process with our users, which we believe is key to building trust in the industry.</p>

                        <p className="banner_text w outfit d-flex"> <HiOutlineCheckBadge className='sb fees_icon' />We understand that not everyone is familiar with crypto, which is why we place great emphasis on creating a simple, aesthetic, and easy-to-use interface for our users. We believe that crypto should be accessible to everyone, not just a select few.</p>

                        <p className="banner_text w outfit d-flex"> <HiOutlineCheckBadge className='sb fees_icon' />At CV Trade Network, we not only support established currencies like Bitcoin but also niche altcoins with real benefits. We are committed to providing responsive and immediate customer support to our users, ensuring that they have a seamless and hassle-free experience on our platform</p>
                </div>
        </section>
    </>
  )
}

export default Fees
