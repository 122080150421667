import React, { useState, useEffect } from 'react';
import InnerHeader from './InnerHeader';
import Select from 'react-select';
import { MdOutlineContentCopy } from "react-icons/md";
import { FaLongArrowAltRight } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import { base_url } from '../../config/config';
import Depositlist from './Depositlist';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';
import toast from 'react-hot-toast';
import { writeText } from 'clipboard-polyfill';


import DepsoitImage from '../../assets/img/ex/deposit.png'


const Deposit = () => {
    const [selectedCoin, setSelectedCoin] = useState(null);
    const [coinOptions, setCoinOptions] = useState([]);
    const [networkOptions, setNetworkOptions] = useState([]);
    const [selectedContractAddress, setSelectedContractAddress] = useState("");
    const [qrCodeValue, setQRCodeValue] = useState("");
    const [nId, setNId] = useState();
    const [deposit_transactions, setdeposit_transactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = localStorage.getItem('theme');
    const token = localStorage.getItem("token");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10); // Limit is fixed at 10 per page
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        getDepositTokens();
        getNetworkId();

        fetchData();

        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [selectedCoin, nId]);

    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        fetchData(skip);
    }, [currentPage, limit, skip]);


    const handlePreviousPage = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = (e) => {
        e.preventDefault();
        const totalPages = Math.ceil(total / limit);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const getDepositTokens = async () => {
        const coinResponse = await fetch(`${base_url}/deposit_tokens`, {
            headers: {
                Authorization: token,
            },
        });
        if (coinResponse.status === 401) {
            toast.dismiss();
            toast.error("Unauthorized access detected!")
            return;
        }
        const coinData = await coinResponse.json();
        const coinOptions = coinData.deposit_tokens.map((coin) => ({
            value: coin._id,
            label: coin.coinName,
            image: coin.image,
        }));

        setCoinOptions(coinOptions);
    }

    const getNetworkId = async () => {
        const depositTokenResponse = await fetch(`${base_url}/deposit_token_details`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                tokenID: selectedCoin?.value,
            }),
        });

        const depositTokenData = await depositTokenResponse.json();

        if (depositTokenData.success === 1) {
            const tokenDetails = depositTokenData.tokenDetails[0];

            if (tokenDetails) {
                const networkDetails = tokenDetails.networkDetails;
                const networkOptions = networkDetails.map((network) => ({
                    value: network._id,
                    label: network.Network,
                }));
                setNetworkOptions(networkOptions);
            }
        }
    }

    const fetchData = async () => {
        try {

            const depositaddressResponse = await fetch(`${base_url}/deposit_address`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({
                    networkID: nId,
                }),
            });

            const depositaddressData = await depositaddressResponse.json();

            if (depositaddressData.success === 1) {
                setSelectedContractAddress(depositaddressData.address);
                setQRCodeValue(depositaddressData.address.walletAddress);
                const { contractAddress, tokenId, networkID, user_id } = depositaddressData;
                const depositTransactionsResponse = await fetch(`${base_url}/deposit_transactions`, {
                    method: "POST",
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        contractAddress,
                        tokenId: selectedCoin?.value,
                        networkID: nId,
                        user_id,
                        limit,
                        skip,
                    }),
                });

                const depositTransactionsData = await depositTransactionsResponse.json();
                // console.log("depositTransactionsData", depositTransactionsData)
                if (depositTransactionsData.success === 1) {
                    setTotal(depositTransactionsData.total)
                    setdeposit_transactions(depositTransactionsData.deposit_transactions);
                } else {
                    toast.dismiss();
                    toast.error("Error fetching deposit transactions");
                }
            } else {
                setSelectedContractAddress("");
                setQRCodeValue("");
            }


        } catch (error) {
            toast.dismiss();
            toast.error("Error fetching data:", error);
        }
    };




    const handleCopy = (text) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text)
                .then(() => {

                    toast.dismiss();
                    toast.success('Address copied to clipboard:', text);
                })
                .catch((error) => {

                    toast.dismiss();
                    toast.error('Error copying to clipboard:', error);
                });
        } else {

            writeText(text)
                .then(() => {
                    toast.dismiss();
                    toast.success('Address copied to clipboard:', text);
                })
                .catch((error) => {
                    toast.dismiss();
                    toast.error('Error copying to clipboard:', error);
                });
        }
    };


    const colourStyles = {
        option: (styles, { data, isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#0000003b' : null,
                color: 'red',
                fontSize: '13px'
            };
        }
    };

    return (
        <>
            <InnerHeader />
            <section className='deposit-page-main'>
                <div className='container'>
                    <div className='de-page-title'>
                        <h2>Deposit Crypto</h2>
                    </div>
                    <div className='dep-row'>
                        <div className='dep-left'>
                            <form>
                                <div className='dep-left-form'>
                                    <div className='dep-inner-flex'>
                                        <div className='label-div mt-1'><span>Select a Coin</span></div>
                                        <div className='form-group input-div'>
                                            <label>Coin</label>
                                            <Select
                                                value={selectedCoin}
                                                onChange={(coin) => setSelectedCoin(coin)}
                                                options={coinOptions}
                                                styles={colourStyles}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        <img src={option.image} alt="coin" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='dep-inner-flex mt-3'>
                                        <div className='label-div mt-1'><span>Select Network</span></div>
                                        <div className='form-group input-div'>
                                            <label>Network</label>
                                            <Select
                                                value={nId?.value}
                                                onChange={(network) => setNId(network.value)}
                                                options={networkOptions}
                                                styles={colourStyles}
                                                formatOptionLabel={option => (
                                                    <div className="cselect-option">
                                                        {option.label}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                    {selectedContractAddress ? (
                                        <div className='dep-inner-flex mt-3'>
                                            <div className='label-div mt-1'><span>Select Address</span></div>
                                            <div className='form-group input-div'>
                                                <label>Address</label>
                                                <div className='address-div'>
                                                    <li>{selectedContractAddress.walletAddress}</li>
                                                    <span onClick={() => handleCopy(selectedContractAddress.walletAddress)}>
                                                        <MdOutlineContentCopy className='copy-text-btn' />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='dep-inner-flex mt-3'>
                                            <div className='label-div mt-1'><span>Select Address</span></div>
                                            <div className='form-group input-div'>
                                                <label>Address</label>
                                                <div className='address-div'>
                                                    <li>None</li>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {qrCodeValue && (
                                        <div className='dep-inner-flex mt-3'>
                                            <div className='label-div mt-1'></div>
                                            <div className='form-group input-div'>
                                                <div className='scaner'>
                                                    <div className='scan-image'>
                                                        <QRCode value={qrCodeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </form>
                            <div className='dep-inner-flex mt-5'>
                                <div className='label-div mt-1'><span></span></div>
                                <div className='form-group input-div'>
                                    <div className='w-footer-main'>
                                        <div className='w-footer-left'>
                                            <li>Recipient Account:</li>
                                            <p>Main Account</p>
                                        </div>
                                        <div className='w-footer-left'>
                                            <li>Deposit Confirmation:</li>
                                            <p>20 block(s)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dep-right'>
                            {/* <h4>FAQ</h4>
                            <div className='paragraph-div'>
                                <ul>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>How do I deposit crypto onto my Bitsta account?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I didn't receive my deposits or I deposit to an incorrect address?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I deposit the wrong crypto?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I forgot to specify the Memo, Tag, or Message for my deposit?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What should I do if I mistakenly deposit through the BSC or BEP20 network and did not receive the deposit?</span></li>
                                    <li><span className='ic'><FaLongArrowAltRight className='right-arrow-icon' /></span><span>What are the common deposit networks?</span></li>
                                </ul>
                            </div> */}

                            <img src={DepsoitImage} alt="Deposit-image" className='w-100' />


                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='w-table-heading mt-5'>
                                <div className='over-pagetitle-01'>
                                    <h2>Deposit History</h2>
                                </div>
                            </div>
                            <div className='market-table'>
                                <table className="table mt-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S No.</th>
                                            <th scope="col">Token Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deposit_transactions.length > 0 ? deposit_transactions?.map((transaction, index) => {
                                            return (
                                                <>
                                                    <tr key={transaction._id}>
                                                        <tr>{index + 1}</tr>
                                                        <td>{transaction.tokenName}</td>
                                                        <td>{transaction.amount}</td>
                                                        <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                                                    </tr>
                                                </>
                                            )
                                        }) :
                                            (<tr className='text-center'>
                                                <td colSpan='6'>Data Not Found</td>
                                            </tr>)
                                        }
                                        {/* {(
                                            deposit_transactions.map((transaction, index) => (
                                                <tr key={transaction._id}>
                                                    <td>{transaction.tokenName}</td>
                                                    <td>{transaction.amount}</td>
                                                    <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
                                                </tr>
                                            ))
                                        )} */}
                                    </tbody>
                                </table>

                                <div className='market-table-footer'>
                                    {deposit_transactions.length > 0 ? (
                                        <>
                                            <div className='total-page'>
                                                <p>Page {currentPage} of {Math.ceil(total / limit)}</p>
                                            </div>
                                            <div className='pagination-div'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handlePreviousPage}>
                                                                <GrFormPrevious />
                                                            </a>
                                                        </li>

                                                        <li className={`page-item`}>
                                                            <a className="page-link page-link1" >
                                                                {currentPage}
                                                            </a>
                                                        </li>

                                                        <li className={`page-item ${currentPage === Math.ceil(total / limit) ? 'disabled' : ''}`}>
                                                            <a className="page-link page-link1" onClick={handleNextPage}>
                                                                <GrFormNext />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>

                                        </>
                                    ) : (
                                        null
                                    )
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default Deposit;
